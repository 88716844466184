import PropTypes from 'prop-types';
import React from 'react';

function IconPayments(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m285.889 146.854c-2.301-.813-4.65-1.661-6.997-2.542v-39.826c5.207 1.42 7.86 4.044 8.044 4.233 3.633 4.125 9.921 4.544 14.072.93 4.165-3.628 4.6-9.944.972-14.108-3.784-4.344-11.753-9.821-23.088-11.473v-7.068c0-5.522-4.477-10-10-10s-10 4.478-10 10v8.229c-1.036.255-2.082.531-3.148.852-13.034 3.927-22.771 15.067-25.411 29.076-2.414 12.812 1.716 25.186 10.779 32.294 4.555 3.573 10.171 6.847 17.779 10.278v50.783c-6.198-.616-10.442-2.352-17.418-6.915-4.622-3.026-10.819-1.728-13.843 2.894-3.023 4.622-1.728 10.82 2.895 13.843 11.076 7.246 18.763 9.578 28.366 10.245v8.421c0 5.522 4.477 10 10 10s10-4.478 10-10v-9.323c19.01-4.322 31.214-20.335 33.869-36.129 3.394-20.181-7.154-37.724-26.871-44.694zm-32.433-15.139c-3.117-2.445-4.479-7.49-3.468-12.854.85-4.51 3.627-9.848 8.905-12.566v29.03c-2.014-1.167-3.855-2.369-5.437-3.61zm39.582 56.516c-1.217 7.24-6.02 14.903-14.146 18.496v-41.134c.113.04.218.079.332.119 15.415 5.448 14.471 18.614 13.814 22.519z" />
        <path d="m76 122h110c8.284 0 15-6.716 15-15s-6.716-15-15-15h-110c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
        <path d="m76 182h110c8.284 0 15-6.716 15-15s-6.716-15-15-15h-110c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
        <path d="m76 242h110c8.284 0 15-6.716 15-15s-6.716-15-15-15h-110c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
        <path d="m296 272h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        <path d="m296 332h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        <path d="m296 392h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        <path d="m502.904 303.684c-4.974-2.578-10.97-2.17-15.549 1.058l-33.899 23.904-33.9-23.904c-5.184-3.655-12.108-3.654-17.292.003l-31.264 22.061v-311.806c0-5.603-3.122-10.738-8.096-13.316-4.974-2.578-10.97-2.17-15.549 1.058l-33.899 23.904-33.898-23.905c-5.185-3.655-12.109-3.654-17.293.003l-33.862 23.895-33.828-23.891c-5.188-3.664-12.12-3.664-17.308 0l-33.819 23.888-33.83-23.889c-5.187-3.663-12.119-3.661-17.305 0l-33.831 23.89-33.83-23.89c-4.577-3.232-10.575-3.643-15.552-1.066-4.976 2.578-8.1 7.715-8.1 13.319v402c0 52.383 42.617 95 95 95h330c46.869 0 85-38.131 85-85v-110c0-5.603-3.122-10.738-8.096-13.316zm-471.904 113.316v-373.044l18.83 13.297c5.188 3.662 12.119 3.662 17.306 0l33.83-23.89 33.832 23.89c5.188 3.664 12.119 3.663 17.307-.001l33.818-23.888 33.823 23.888c5.186 3.662 12.114 3.662 17.302.004l33.869-23.899 33.896 23.902c5.184 3.654 12.105 3.654 17.289 0l18.898-13.327v383.068c0 20.962 7.643 40.163 20.269 55h-265.269c-35.841 0-65-29.159-65-65zm450 10c0 30.327-24.673 55-55 55s-55-24.673-55-55v-65.227c2.131-.375 4.2-1.214 6.047-2.518l33.869-23.899 33.896 23.902c5.184 3.654 12.105 3.654 17.289 0l18.899-13.326z" />
      </g>
    </svg>
  );
}

IconPayments.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconPayments.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconPayments;
