import PropTypes from 'prop-types';
import React from 'react';

import styles from './Logo.module.scss';

function Logo(props) {
  return (
    <div
      className={styles.logo}
      style={{
        width: `${props.width}em`,
        height: `${props.height}em`,
        margin: `${props.margin}em`
      }}
    />
  );
}

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.number
};

Logo.defaultProps = {
  width: 12,
  height: 7,
  margin: 0
};

export default Logo;
