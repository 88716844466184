import PropTypes from 'prop-types';
import React from 'react';

function IconTeam(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M175.8,464.9c-4.1,0-7.5,3.4-7.5,7.5v32.1c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-32.1
			C183.3,468.3,180,464.9,175.8,464.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M336.2,464.9c-4.1,0-7.5,3.4-7.5,7.5v32.1c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-32.1
			C343.7,468.3,340.3,464.9,336.2,464.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M115.1,305.4c-17.4-8.7-50.1-8.8-51.5-8.8c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c8.4,0,32.9,1.3,44.7,7.2
			c1.1,0.5,2.2,0.8,3.3,0.8c2.8,0,5.4-1.5,6.7-4.1C120.3,311.8,118.8,307.3,115.1,305.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M39.6,448.9c-4.1,0-7.5,3.4-7.5,7.5v48.1c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-48.1C47.1,452.3,43.7,448.9,39.6,448.9z
			"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M494.6,420.9l-30.4-15.2c-0.2-0.1-0.3-0.3-0.3-0.5l0-15.3c12.5-8.6,21.3-22.2,23.5-38.1c5.6-9.7,8.6-20.6,8.6-31.8
			c0-20.8-10-39.2-25.4-50.8c0.9-3,1.4-6.1,1.4-9.3c0-19.6-17.7-35.6-39.6-35.6s-39.6,16-39.6,35.6c0,3.2,0.5,6.3,1.4,9.3
			c-9.2,6.9-16.4,16.3-20.8,27.1c-1.9-18.3-3.8-39.9-5.7-65c-2.5-33.2-14.4-61.2-34.5-81.1c-20-19.7-46.7-30.1-77.2-30.1
			s-57.2,10.4-77.2,30.1c-20.1,19.8-32.1,47.9-34.5,81.1c-0.7,8.8-1.3,17.2-2,25.1H71.6c-26.2,0-47.6,21.3-47.6,47.6v0.4
			c0,6.1,1.4,12.2,4.2,17.7l3.8,7.7v12.2c0,19.4,9.6,36.8,24,47.3v10.8c0,1.4-0.9,2.6-2.3,3l-31,8.8C9.4,416,0,428.5,0,442.5v62
			c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-62c0-7.4,4.9-13.9,12-15.9l31-8.8c1.1-0.3,2.2-0.8,3.2-1.3l19,19v69
			c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-69l19.1-19.1c2.6,1.3,5.1,2,7,2.5l7.6,2.2c-10.3,7.3-16.7,19.3-16.7,32.2v51.3
			c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-51.3c0-9.7,5.7-18.5,14.6-22.5l42.9-18.9c13.4,26.9,40.9,44.1,71.3,44.1
			c30.5,0,57.8-17.1,71.3-44l42.9,18.9c8.9,3.9,14.6,12.7,14.6,22.5v51.3c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-51.3
			c0-9.6-3.5-18.6-9.5-25.7l11.6-5.8l8.7,8.3c6.1,5.8,13.9,8.7,21.7,8.7c7.8,0,15.6-2.9,21.7-8.7l8.7-8.3l25.1,12.5
			c5.6,2.8,9.1,8.5,9.1,14.8v55.4c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-55.4C512,437.1,505.3,426.3,494.6,420.9z M432.4,239.5
			c13.5,0,24.6,9.2,24.6,20.6c0,0.5,0,0.9-0.1,1.4c-7.5-3.2-15.8-4.9-24.5-4.9c-8.7,0-16.9,1.8-24.5,4.9c0-0.5-0.1-0.9-0.1-1.4
			C407.8,248.7,418.8,239.5,432.4,239.5z M87.7,421.7l-17.1-17.1c0.4-1.4,0.5-2.9,0.5-4.4v-3.2c4.7,1.5,9.7,2.5,14.8,2.6
			c0.6,0,1.2,0,1.7,0c5.7,0,11.2-0.9,16.5-2.5v3c0,1.6,0.2,3.1,0.5,4.5L87.7,421.7z M86.4,384.8c-21.7-0.6-39.3-19.8-39.3-42.6v-14
			c0-1.2-0.3-2.3-0.8-3.4l-4.6-9.3c-1.7-3.4-2.6-7.2-2.6-11v-0.4c0-18,14.6-32.6,32.6-32.6h64.6v31.1c0,5-1.2,10.1-3.4,14.6
			l-3.8,7.6c-0.5,1-0.8,2.2-0.8,3.4v16c0,11.1-4.4,21.4-12.3,29.1C108,381,97.5,385.1,86.4,384.8z M149.1,411.2l-23.7-6.8
			c-6.2-1.8-6.2-2.5-6.2-4.1l0-10.3c2.5-1.7,4.9-3.7,7.2-5.9c1.5-1.5,2.9-3,4.2-4.6c1.2,2.4,3.1,4.6,5.4,6.1
			c6,3.9,17.4,10.4,34,16.3L149.1,411.2z M256,440.9c-24.4,0-46.5-13.6-57.5-35.1l10.9-4.8c8.5-3.8,14-12.2,14-21.5v-18
			c10.1,4,21.1,6.3,32.6,6.3s22.5-2.2,32.6-6.3v18c0,9.3,5.5,17.8,14,21.5l10.9,4.8C302.5,427.3,280.5,440.9,256,440.9z M321,392.7
			l-12.4-5.5c-3.1-1.4-5.1-4.4-5.1-7.8v-25.8c24.1-15.6,40.1-42.8,40.1-73.6V266c0-9.1-3.9-17.8-10.7-23.7
			c-15.1-13.1-49.2-36-108.3-41.9c-4.1-0.4-7.8,2.6-8.2,6.7c-0.4,4.1,2.6,7.8,6.7,8.2c55,5.5,86.2,26.3,100,38.3
			c3.5,3,5.5,7.5,5.5,12.3V280c0,40.1-32.6,72.7-72.7,72.7s-72.7-32.6-72.7-72.7v-6.3c0-2.9,1.6-5.5,4.3-7.1
			c9.4-5.4,21.7-15.4,27.4-32.4c1.3-3.9-0.8-8.2-4.7-9.5c-3.9-1.3-8.2,0.8-9.5,4.7c-4.2,12.5-13.5,20-20.7,24.1
			c-7.3,4.2-11.8,11.9-11.8,20.1v6.3c0,30.8,16,58,40.1,73.6v25.8c0,3.4-2,6.4-5.1,7.8l-12.4,5.5c-24.2-6.5-39.6-15.1-46.8-19.7
			c-0.1-0.1-0.2-0.2-0.2-0.4c2.6-15.5,9.3-60.1,15.2-140.1c2.2-29.5,12.6-54.2,30.1-71.5c17.1-16.9,40.2-25.8,66.6-25.8
			s49.5,8.9,66.6,25.8c17.5,17.2,27.9,42,30.1,71.5c5.9,80,12.6,124.6,15.2,140.1c0,0.2,0,0.3-0.2,0.4
			C360.7,377.6,345.2,386.2,321,392.7z M400.8,405.2c0,0.2-0.1,0.4-0.3,0.5L377,417.5c-0.3-0.1-0.5-0.3-0.8-0.4l-34.3-15.1
			c16.7-6,28-12.4,34-16.3c5.2-3.3,7.9-9.4,6.8-15.5c-0.1-0.3-0.1-0.7-0.2-1.1c4.2,8.5,10.5,15.7,18.2,21V405.2z M443.8,419.2
			c-6.4,6.1-16.4,6.1-22.8,0l-7-6.6c1.2-2.2,1.8-4.7,1.8-7.3v-8c5.2,1.6,10.8,2.5,16.5,2.5c5.8,0,11.3-0.9,16.5-2.5l0,8
			c0,2.6,0.6,5.1,1.8,7.3L443.8,419.2z M432.4,384.8c-20.8,0-38.1-15.5-40.3-36.2c-0.1-1.1-0.5-2.2-1.1-3.1
			c-4.7-7.7-7.2-16.4-7.2-25.4c0-26.8,21.8-48.6,48.6-48.6c26.8,0,48.6,21.8,48.6,48.6c0,5.2-0.8,10.3-2.5,15.2
			c-21.3-21.6-58.8-30.1-60.6-30.5c-2.2-0.5-4.5,0.1-6.3,1.5c-1.8,1.4-2.8,3.6-2.8,5.8c0,0-0.3,5.3-5.8,10.7
			c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0c4.2-4.2,6.7-8.5,8.1-12.1c12.6,3.7,38.8,13.2,50.6,29.8
			C469,370.5,452.2,384.8,432.4,384.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M472.4,458.8c-4.1,0-7.5,3.4-7.5,7.5v38.2c0,4.1,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-38.2
			C479.9,462.2,476.6,458.8,472.4,458.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M413.5,74h-6.9c-5.5,0-10,4.5-10,10s4.5,10,10,10h6.9c5.5,0,10-4.5,10-10S419,74,413.5,74z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M462,74h-6.9c-5.5,0-10,4.5-10,10s4.5,10,10,10h6.9c5.5,0,10-4.5,10-10S467.5,74,462,74z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M434.3,94.8c-5.5,0-10,4.5-10,10v6.9c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10v-6.9C444.3,99.3,439.8,94.8,434.3,94.8z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M434.3,46.3c-5.5,0-10,4.5-10,10v6.9c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10v-6.9C444.3,50.8,439.8,46.3,434.3,46.3z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M56.3,74h-6.9c-5.5,0-10,4.5-10,10s4.5,10,10,10h6.9c5.5,0,10-4.5,10-10S61.8,74,56.3,74z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M104.8,74H98c-5.5,0-10,4.5-10,10s4.5,10,10,10h6.9c5.5,0,10-4.5,10-10S110.3,74,104.8,74z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M77.1,94.8c-5.5,0-10,4.5-10,10v6.9c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10v-6.9C87.1,99.3,82.7,94.8,77.1,94.8z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M77.1,46.3c-5.5,0-10,4.5-10,10v6.9c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10v-6.9C87.1,50.8,82.7,46.3,77.1,46.3z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M256,39.1c-5.5,0-10,4.5-10,10v26.3c0,5.5,4.5,10,10,10s10-4.5,10-10V49.1C266,43.6,261.5,39.1,256,39.1z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M263.1,3.9C261.2,2.1,258.6,1,256,1s-5.2,1.1-7.1,2.9c-1.9,1.9-2.9,4.4-2.9,7.1s1.1,5.2,2.9,7.1s4.4,2.9,7.1,2.9
			s5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1S264.9,5.8,263.1,3.9z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M221,41.1l-14.6-14.6c-3.9-3.9-10.2-3.9-14.1,0c-3.9,3.9-3.9,10.2,0,14.1l14.6,14.6c2,2,4.5,2.9,7.1,2.9s5.1-1,7.1-2.9
			C224.9,51.4,224.9,45,221,41.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M319.8,25.9c-3.9-3.9-10.2-3.9-14.1,0l-15.1,15.1c-3.9,3.9-3.9,10.2,0,14.1c2,2,4.5,2.9,7.1,2.9s5.1-1,7.1-2.9l15.1-15.1
			C323.7,36.2,323.7,29.8,319.8,25.9z"
          />
        </g>
      </g>
    </svg>
  );
}

IconTeam.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconTeam.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconTeam;
