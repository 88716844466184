import { Record } from 'immutable';

import { isObjectEmpty } from '../../services/utilities';

export const actionTypes = {
  BRIDES_SET_LIST: 'BRIDES_SET_LIST',
  BRIDES_UPDATE_BRIDE: 'BRIDES_UPDATE_BRIDE',
  BRIDES_DELETE_BRIDE: 'BRIDES_DELETE_BRIDE',
  BRIDES_ADD_BRIDE: 'BRIDES_ADD_BRIDE'
};

export const InitialState = Record(
  {
    list: [],
    lastEvaluatedKey: null,
    statistics: {},
    priority: {},
    taskTime: {},
    production: {},
  },
  'bridesRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BRIDES_SET_LIST: {
      return state.merge({
        list: action.brides,
        lastEvaluatedKey: action.lastEvaluatedKey,
        statistics: action.statistics,
        priority: action.priority,
        taskTime: action.taskTime,
        production: action.production
      });
    }
    case actionTypes.BRIDES_UPDATE_BRIDE: {
      const list = state.get('list');
      const { brideData, brideId } = action;
      const noData = isObjectEmpty(brideData);
      let updatedList = [];

      if (noData) {
        updatedList = list.filter(el => el.id !== brideId);
      } else {
        updatedList = list.map(el => {
          if (el.id === brideId) {
            return { ...brideData };
          }
          return el;
        });
      }

      return state.set('list', updatedList);
    }
    case actionTypes.BRIDES_DELETE_BRIDE: {
      const { brideId } = action;
      const updatedList = state.get('list').filter(el => el.id !== brideId);

      return state.set('list', updatedList);
    }
    case actionTypes.BRIDES_ADD_BRIDE: {
      const { brideData } = action;
      const list = state.get('list');
      const updatedList = [...list, brideData];
      
      return state.set('list', updatedList);
    }
    default:
      return state;
  }
};
