const ACCESS_ACTION = {
  DASHBOARD_PAGE_VISIT: 'DASHBOARD_PAGE:VISIT',
  PROFILE_PAGE_VISIT: 'PROFILE_PAGE:VISIT',
  TEAM_USERS_PAGE_VISIT: 'TEAM_USERS_PAGE:VISIT',
  BRIDE_NEW_PAGE_VISIT: 'BRIDE_NEW_PAGE:VISIT',
  BRIDE_DETAILS_PAGE_VISIT: 'BRIDE_DETAILS_PAGE:VISIT',
  BRIDE_DETAILS_PAGE_EDIT: 'BRIDE_DETAILS_PAGE:EDIT',
  BRIDE_DETAILS_PAGE_EDIT_SHOULD_FILL_IN_ENGAGEMENT_MONTH:
    'BRIDE_DETAILS_PAGE:EDIT:SHOULD:FILL_IN_ENGAGEMENT_MONTH',
  BRIDE_DETAILS_PAGE_EDIT_EXTENDED: 'BRIDE_DETAILS_PAGE:EDIT:EXTENDED',
  BRIDE_DETAILS_PAGE_EDIT_OWN_APPOINTMENT_CALENDAR:
    'BRIDE_DETAILS_PAGE:EDIT:OWN_APPOINTMENT_CALENDAR',
  BRIDE_DETAILS_PAGE_PAYMENT_VISIT: 'BRIDE_DETAILS_PAGE_PAYMENT:VISIT',
  BRIDE_DETAILS_PAGE_INTEREST_VISIT: 'BRIDE_DETAILS_PAGE_INTEREST:VISIT',
  BRIDE_DETAILS_PAGE_ADDRESS_VISIT: 'BRIDE_DETAILS_PAGE_ADDRESS:VISIT',
  BRIDE_DETAILS_PAGE_VIEW_SALES_OWNER: 'BRIDE_DETAILS_PAGE_SALES_OWNER:VIEW',
  BRIDE_DETAILS_PAGE_EDIT_PRODUCTION_OWNER: 'BRIDE_DETAILS_PAGE_PRODUCTION_OWNER:EDIT',
  BRIDE_DETAILS_PAGE_CONTACT_VISIT: 'BRIDE_DETAILS_PAGE_CONTACT:VISIT',
  BRIDE_DETAILS_PAGE_REFERRALS_VISIT: 'BRIDE_DETAILS_PAGE_REFERRALS:VISIT',
  BRIDE_DETAILS_PAGE_PICKUP_VISIT: 'BRIDE_DETAILS_PAGE_PICKUP:VISIT',
  BRIDE_DETAILS_PAGE_EDIT_PAYMENT_WRITE: 'BRIDE_DETAILS_PAGE:EDIT:PAYMENT:WRITE',
  BRIDE_DETAILS_PAGE_EDIT_PAYMENT_ADD: 'BRIDE_DETAILS_PAGE:EDIT:PAYMENT:ADD',
  BRIDE_DETAILS_PAGE_EDIT_PAYMENT_DETAILS_WRITE: 'BRIDE_DETAILS_PAGE:EDIT:PAYMENT_DETAILS_WRITE',
  BRIDE_DETAILS_PAGE_EDIT_ROYALTY_WRITE: 'BRIDE_DETAILS_PAGE:EDIT:ROYALTY_WRITE',
  BRIDE_DETAILS_PAGE_EDIT_REMOVE_APPOINTMENT: 'BRIDE_DETAILS_PAGE:EDIT:REMOVE_APPOINTMENT',
  BRIDE_DETAILS_PAGE_EDIT_ENGAGEMENT_WEDDING: 'BRIDE_DETAILS_PAGE:EDIT:ENGAGEMENT_WEDDING',
  BRIDE_DETAILS_PAGE_EDIT_ADDRESS: 'BRIDE_DETAILS_PAGE:EDIT:ADDRESS',
  BRIDE_DETAILS_PAGE_EDIT_CONTACT: 'BRIDE_DETAILS_PAGE:EDIT:CONTACT',
  BRIDE_DETAILS_PAGE_EDIT_REFERRALS: 'BRIDE_DETAILS_PAGE:EDIT:REFERRALS',
  BRIDE_DETAILS_PAGE_EDIT_INTEREST: 'BRIDE_DETAILS_PAGE:EDIT:INTEREST',
  BRIDE_DETAILS_PAGE_EDIT_MEASUREMENTS: 'BRIDE_DETAILS_PAGE:EDIT:MEASUREMENTS',
  BRIDE_DETAILS_PAGE_EDIT_FITTING: 'BRIDE_DETAILS_PAGE:EDIT:FITTING',
  BRIDE_DETAILS_PAGE_EDIT_FITTING_PRODUCTION: 'BRIDE_DETAILS_PAGE:EDIT:FITTING:PRODUCTION',
  BRIDE_DETAILS_PAGE_FITTING_PRODUCTION_CLOSE_ALL: 'BRIDE_DETAILS_PAGE_FITTING_PRODUCTION_CLOSE_ALL',
  BRIDE_DETAILS_PAGE_EDIT_PICKUP: 'BRIDE_DETAILS_PAGE:EDIT:PICKUP',
  BRIDE_DETAILS_PAGE_EDIT_IMAGES: 'BRIDE_DETAILS_PAGE:EDIT:IMAGES',
  BRIDE_DETAILS_PAGE_SECTIONS_UNCOLLAPSED: 'BRIDE_DETAILS_PAGE_SECTIONS_UNCOLLAPSED',
  BRIDES_PAGE_VISIT: 'BRIDES_PAGE:VISIT',
  BRIDES_PRIORITY_VISIT: 'BRIDES_PRIORITY:VISIT',
  BRIDES_PRODUCTION_VISIT: 'BRIDES_PRODUCTION:VISIT',
  SUPPLIERS_PAGE_VISIT: 'SUPPLIERS_PAGE:VISIT',
  SUPPLIER_DETAILS_PAGE_VISIT: 'SUPPLIER_DETAILS_PAGE:VISIT',
  SUPPLIER_NEW_PAGE_VISIT: 'SUPPLIER_NEW_PAGE:VISIT',
  INVENTORY_PAGE_VISIT: 'INVENTORY_PAGE_VISIT:VISIT',
  INVENTORY_DETAILS_PAGE_GOWN_EDIT: 'INVENTORY_DETAILS_PAGE:GOWN:EDIT',
  INVENTORY_DETAILS_PAGE_VISIT: 'INVENTORY_DETAILS_PAGE_VISIT:VISIT',
  INVENTORY_DETAILS_SUPPLIER_VISIT: 'INVENTORY_DETAILS_SUPPLIER:VISIT',
  INVENTORY_DETAILS_OWNER_HISTORY_VISIT: 'INVENTORY_DETAILS_OWNER_HISTORY:VISIT',
  INVENTORY_NEW_PAGE_VISIT: 'INVENTORY_NEW_PAGE:VISIT',
  BRIDES_PAGE_STATISTICS: 'BRIDES_PAGE:STATISTICS',
  BRIDES_PAGE_FILTER_BASIC: 'BRIDES_PAGE:FILTER:BASIC',
  BRIDES_PAGE_FILTER_EXTENDED: 'BRIDES_PAGE:FILTER:EXTENDED',
  BRIDES_PAGE_LIST_COLUMNS_LIMITED: 'BRIDES_PAGE:LIST:COLUMNS:LIMITED',
  BRIDES_PAGE_LIST_DELETE: 'BRIDES_PAGE:LIST:DELETE',
  BRIDES_IMPORT_VISIT: 'BRIDES_IMPORT_PAGE:VISIT',
  BRIDES_TASK_TIME_VISIT: 'BRIDES_TASK_TIME:VISIT',
  AVAILABILITY_PAGE_VISIT: 'AVAILABILITY_PAGE:VISIT',
  APPOINTMENTS_PAGE_VISIT: 'APPOINTMENTS_PAGE:VISIT',
  CART_PAGE_VISIT: 'CART_PAGE:VISIT',
  CALENDAR_PAGE_VISIT: 'CALENDAR_PAGE:VISIT',
  ACCESSORIES_PAGE_VISIT: 'ACCESSORIES_PAGE:VISIT',
  ACCESSORIES_PAGE_FILTER_EXTENDED: 'ACCESSORIES_PAGE:FILTER:EXTENDED',
  ACCESSORIES_PAGE_FILTER_BASIC: 'ACCESSORIES_PAGE:FILTER:BASIC',
  ACCESSORIES_PAGE_DETAILS_DELIVERY: 'ACCESSORIES_PAGE_DETAILS_DELIVERY',
  PRODUCTION_PAGE_VISIT: 'PRODUCTION_PAGE:VISIT',
  PRODUCTION_ALL_PAGE_VISIT: 'PRODUCTION_ALL_PAGE:VISIT',
  PRODUCTION_SETTINGS_PAGE_VISIT: 'PRODUCTION_SETTINGS_PAGE:VISIT',
  SAMPLES_PAGE_VISIT: 'SAMPLES_PAGE:VISIT',
  SAMPLE_NEW_PAGE_VISIT: 'SAMPLE_NEW_PAGE:VISIT',
  SAMPLES_PAGE_LIST_DELETE: 'SAMPLES_PAGE:LIST:DELETE',
  FINANCIALS_PAGE_VISIT: 'FINANCIALS_PAGE:VISIT',
  DELIVERY_PAGE_VISIT: 'DELIVERY_PAGE:VISIT',
  DELIVERY_PAGE_DRIVER_EDIT: 'DELIVERY_PAGE:DRIVER:EDIT',
  DELIVERY_PAGE_DRIVER_NOTES_EDIT: 'DELIVERY_PAGE:DRIVER:NOTES:EDIT',
  DELIVERY_PAGE_DELIVERY_EDIT: 'DELIVERY_PAGE:DELIVERY:EDIT',
  DELIVERY_PAGE_DELIVERY_NOTES_EDIT: 'DELIVERY_PAGE:DELIVERY:NOTES:EDIT',
  DELIVERY_PAGE_VIEW_SCHEDULE: 'DELIVERY_PAGE:VIEW:SCHEDULE',
  DELIVERY_PAGE_EDIT_SCHEDULE: 'DELIVERY_PAGE:EDIT:SCHEDULE',
  DELIVERY_PAGE_SHOW_PICKUP_RETURN_FULL: 'DELIVERY_PAGE_SHOW_PICKUP_RETURN_FULL',
  DELIVERY_PAGE_CUSTOMER_ACCEPT_PICKUP: 'DELIVERY_PAGE_CUSTOMER_ACCEPT:PICKUP',
  DELIVERY_PAGE_CUSTOMER_ACCEPT_RETURN: 'DELIVERY_PAGE_CUSTOMER_ACCEPT:RETURN',
  DELIVERY_PAGE_ACCEPT_RETURN: 'DELIVERY_PAGE_ACCEPT_RETURN',
  DELIVERY_PAGE_SHOW_DRIVER_FULL: 'DELIVERY_PAGE_SHOW_DRIVER_FULL'
};

export default ACCESS_ACTION;
