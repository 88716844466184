import PropTypes from 'prop-types';
import React from 'react';

function IconPriority(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 600 600"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m392.567 48.203h-40.166-35.001c-6.751-27.633-31.719-48.203-61.399-48.203-29.682 0-54.651 20.57-61.402 48.203h-34.999-40.166c-30.419 0-55.167 24.747-55.167 55.164v353.466c0 30.419 24.748 55.167 55.167 55.167h273.132c30.419 0 55.166-24.748 55.166-55.167v-353.466c.001-30.417-24.746-55.164-55.165-55.164zm-184.766 30h.003c8.284 0 15-6.716 15-15 0-.013-.002-.025-.002-.038.021-18.291 14.905-33.165 33.199-33.165 18.305 0 33.197 14.895 33.197 33.203 0 8.284 6.716 15 15 15h.012 33.191v25.164c0 13.876-11.289 25.166-25.166 25.166h-112.468c-13.877 0-25.167-11.29-25.167-25.166v-25.164zm209.932 378.63c0 13.877-11.289 25.167-25.166 25.167h-273.133c-13.877 0-25.167-11.29-25.167-25.167v-353.466c0-13.876 11.29-25.164 25.167-25.164h25.166v25.164c0 30.418 24.748 55.166 55.167 55.166h112.467c30.419 0 55.166-24.748 55.166-55.166v-25.164h25.166c13.877 0 25.166 11.289 25.166 25.164v353.466z" />
      <path d="m181.131 389.993-21.529 21.528-5.463-5.461c-5.859-5.856-15.355-5.855-21.213.003-5.857 5.859-5.855 15.356.003 21.213l16.069 16.064c2.929 2.928 6.767 4.392 10.605 4.392s7.678-1.465 10.606-4.393l32.134-32.133c5.858-5.858 5.858-15.355.001-21.213-5.857-5.857-15.355-5.858-21.213 0z" />
      <path d="m181.131 293.593-21.529 21.529-5.463-5.461c-5.858-5.857-15.356-5.856-21.213.004-5.857 5.859-5.855 15.357.004 21.213l16.069 16.064c2.929 2.928 6.766 4.392 10.605 4.392 3.838 0 7.678-1.465 10.606-4.393l32.134-32.134c5.858-5.858 5.858-15.355 0-21.213-5.858-5.86-15.356-5.86-21.213-.001z" />
      <path d="m148.997 250.544c2.929 2.928 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.393l32.134-32.134c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-21.528 21.528-5.463-5.462c-5.858-5.857-15.356-5.857-21.213.001-5.858 5.858-5.857 15.356.001 21.213z" />
      <path d="m368.467 417.732h-112.463c-8.284 0-15 6.716-15 15s6.716 15 15 15h112.463c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      <path d="m368.467 321.333h-112.463c-8.284 0-15 6.716-15 15s6.716 15 15 15h112.463c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      <path d="m368.467 224.936h-112.463c-8.284 0-15 6.716-15 15s6.716 15 15 15h112.463c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    </svg>
  );
}

IconPriority.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconPriority.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconPriority;
