import PropTypes from 'prop-types';
import React from 'react';

import Content from '../components/Content/Content';
import Footer from '../components/Footer/Footer';
import Main from '../components/Main/Main';
import Navbar from '../components/Navbar/Navbar';
import Sidebar from '../components/Sidebar/Sidebar';
import Wrapper from '../components/Wrapper/Wrapper';
import styles from './DashboardLayout.module.scss';

function DashboardLayout({ children }) {
  return (
    <Wrapper>
      <Sidebar />
      <div className={`w-100 ${styles.mainBlockContainer}`}>
        <Main className={styles.mainBlock}>
          <Navbar />
          <Content>{children}</Content>
        </Main>
        <Footer />
      </div>
    </Wrapper>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default DashboardLayout;
