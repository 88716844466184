import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';

import AsyncLoading from '../components/AsyncLoading/AsyncLoading';
import ACCESS_ACTION from '../constants/access-actions';
import ROUTES, { SETTINGS_ROUTES } from '../constants/routes';
import AuthLayout from '../layouts/AuthLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import ModalLayout from '../layouts/ModalLayout';
import AppliedRoute from './AppliedRoute/AppliedRoute';
import AuthenticatedRoute from './AuthenticatedRoute/AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute/UnauthenticatedRoute';

const AsyncSignIn = loadable(() => import('../pages/SignIn/SignIn'), {
  fallback: <AsyncLoading />
});
const AsyncSignUp = loadable(() => import('../pages/SignUp/SignUp'), {
  fallback: <AsyncLoading />
});
const AsyncSignUpVerification = loadable(
  () => import('../pages/SignUpVerification/SignUpVerification'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncDashboard = loadable(() => import('../pages/Dashboard/Dashboard'), {
  fallback: <AsyncLoading />
});
const AsyncSettingsProfile = loadable(() => import('../pages/Settings/Settings'), {
  fallback: <AsyncLoading />
});
const AsyncTeamUsers = loadable(() => import('../pages/Team/TeamUsers/TeamUsers'), {
  fallback: <AsyncLoading />
});
const AsyncSuppliersList = loadable(
  () => import('../pages/Suppliers/SuppliersList/SuppliersList'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncSupplierDetails = loadable(
  () => import('../pages/Suppliers/SupplierDetails/SupplierDetails'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncNewSupplier = loadable(() => import('../pages/Suppliers/NewSupplier/NewSupplier'), {
  fallback: <AsyncLoading />
});
const AsyncInventoryList = loadable(
  () => import('../pages/Inventory/InventoryList/InventoryList'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncInventoryDetails = loadable(
  () => import('../pages/Inventory/InventoryDetails/InventoryDetails'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncNewInventory = loadable(() => import('../pages/Inventory/NewInventory/NewInventory'), {
  fallback: <AsyncLoading />
});
const AsyncBrides = loadable(() => import('../pages/Brides/BridesListPage/Brides'), {
  fallback: <AsyncLoading />
});
const AsyncBridesPriority = loadable(() => import('../pages/Brides/PriorityPage/Priority'), {
  fallback: <AsyncLoading />
});
const AsyncBridesTaskTime = loadable(() => import('../pages/Brides/TaskTimePage/TaskTime'), {
  fallback: <AsyncLoading />
});
const AsyncBridesProduction = loadable(() => import('../pages/Brides/ProductionPage/Production'), {
  fallback: <AsyncLoading />
});
const AsyncBrideDetails = loadable(
  () => import('../pages/Brides/BrideDetailsPage/BrideDetailsPage'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncNewBride = loadable(() => import('../pages/Brides/NewBride/NewBride'), {
  fallback: <AsyncLoading />
});
const AsyncBridesImport = loadable(() => import('../pages/BridesImport/BridesImport'), {
  fallback: <AsyncLoading />
});
const AsyncAvailability = loadable(() => import('../pages/Availability/Availability'), {
  fallback: <AsyncLoading />
});
const AsyncAppointments = loadable(() => import('../pages/Appointments/Appointments'), {
  fallback: <AsyncLoading />
});
const AsyncCart = loadable(() => import('../pages/Cart/Cart'), {
  fallback: <AsyncLoading />
});
const AsyncCalendar = loadable(() => import('../pages/Calendar/Calendar'), {
  fallback: <AsyncLoading />
});
const AsyncAccessories = loadable(() => import('../pages/Accessories/Accessories'), {
  fallback: <AsyncLoading />
});
const AsyncSamples = loadable(() => import('../pages/Samples/SamplesListPage/Samples'), {
  fallback: <AsyncLoading />
});
const AsyncSamplesTaskTime = loadable(
  () => import('../pages/Samples/SamplesTaskTimePage/TaskTime'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncSampleDetails = loadable(
  () => import('../pages/Samples/SampleDetailsPage/SampleDetailsPage'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncNewSample = loadable(() => import('../pages/Samples/NewSample/NewSample'), {
  fallback: <AsyncLoading />
});
const AsyncProduction = loadable(() => import('../pages/Production/ProductionPage/Production'), {
  fallback: <AsyncLoading />
});
const AsyncProductionAll = loadable(
  () => import('../pages/Production/ProductionAllPage/ProductionAll'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncProductionSettings = loadable(
  () => import('../pages/Production/SettingsPage/Settings'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncPayments = loadable(() => import('../pages/Financials/PaymentsPage/Payments'), {
  fallback: <AsyncLoading />
});
const AsyncDelivery = loadable(() => import('../pages/Delivery/DeliveryListPage/Delivery'), {
  fallback: <AsyncLoading />
});
const AsyncDeliveryDetails = loadable(
  () => import('../pages/Delivery/DeliveryDetails/DeliveryDetails'),
  {
    fallback: <AsyncLoading />
  }
);
const AsyncNotFound = loadable(() => import('../pages/NotFound/NotFound'), {
  fallback: <AsyncLoading />
});
const AsyncResetPassword = loadable(() => import('../pages/ResetPassword/ResetPassword'), {
  fallback: <AsyncLoading />
});
const AsyncResetPasswordNew = loadable(() => import('../pages/ResetPasswordNew/ResetPasswordNew'), {
  fallback: <AsyncLoading />
});

class Routes extends Component {
  previousLocation = this.props.location;

  componentDidUpdate(prevProps) {
    // set previousLocation if props.location is not modal
    if (
      this.props.history.action !== 'POP' &&
      (!prevProps.location.state || !prevProps.location.state.modal)
    ) {
      this.previousLocation = prevProps.location;
    }
    return null;
  }

  render() {
    const { childProps, location } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    return (
      <>
        <Switch location={isModal ? this.previousLocation : location}>
          <UnauthenticatedRoute
            component={AsyncSignIn}
            exact
            layout={AuthLayout}
            path={ROUTES.SIGN_IN}
            props={childProps}
          />
          <UnauthenticatedRoute
            component={AsyncSignUp}
            exact
            layout={AuthLayout}
            path={ROUTES.SIGN_UP}
            props={childProps}
          />
          <UnauthenticatedRoute
            component={AsyncSignUpVerification}
            exact
            layout={AuthLayout}
            path={ROUTES.SIGN_UP_VERIFICATION(':login')}
            props={childProps}
          />
          <UnauthenticatedRoute
            component={AsyncResetPassword}
            exact
            layout={AuthLayout}
            path={ROUTES.RESET_PASSWORD}
            props={childProps}
          />
          <UnauthenticatedRoute
            component={AsyncResetPasswordNew}
            exact
            layout={AuthLayout}
            path={ROUTES.RESET_PASSWORD_CONFIRM(':login')}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.DASHBOARD_PAGE_VISIT}
            component={AsyncDashboard}
            exact
            layout={DashboardLayout}
            path={ROUTES.DASHBOARD}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.PROFILE_PAGE_VISIT}
            component={AsyncSettingsProfile}
            exact
            layout={DashboardLayout}
            path={SETTINGS_ROUTES}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.TEAM_USERS_PAGE_VISIT}
            component={AsyncTeamUsers}
            exact
            layout={DashboardLayout}
            path={ROUTES.TEAM_USERS}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDES_PAGE_VISIT}
            component={AsyncBrides}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT}
            component={AsyncBrideDetails}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_DETAILS(':brideId')}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT}
            component={AsyncNewBride}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_NEW}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SUPPLIERS_PAGE_VISIT}
            component={AsyncSuppliersList}
            exact
            layout={DashboardLayout}
            path={ROUTES.SUPPLIERS}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SUPPLIER_DETAILS_PAGE_VISIT}
            component={AsyncSupplierDetails}
            exact
            layout={DashboardLayout}
            path={ROUTES.SUPPLIERS_DETAILS(':supplierId')}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SUPPLIER_NEW_PAGE_VISIT}
            component={AsyncNewSupplier}
            exact
            layout={DashboardLayout}
            path={ROUTES.SUPPLIERS_NEW}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.INVENTORY_PAGE_VISIT}
            component={AsyncInventoryList}
            exact
            layout={DashboardLayout}
            path={ROUTES.INVENTORY}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT}
            component={AsyncInventoryDetails}
            exact
            layout={DashboardLayout}
            path={ROUTES.INVENTORY_DETAILS(':inventoryId')}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.INVENTORY_NEW_PAGE_VISIT}
            component={AsyncNewInventory}
            exact
            layout={DashboardLayout}
            path={ROUTES.INVENTORY_NEW}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDES_IMPORT_VISIT}
            component={AsyncBridesImport}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_IMPORT}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDES_PRIORITY_VISIT}
            component={AsyncBridesPriority}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_PRIORITY}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDES_TASK_TIME_VISIT}
            component={AsyncBridesTaskTime}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_TASK_TIME}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.BRIDES_PRODUCTION_VISIT}
            component={AsyncBridesProduction}
            exact
            layout={DashboardLayout}
            path={ROUTES.BRIDES_PRODUCTION}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.AVAILABILITY_PAGE_VISIT}
            component={AsyncAvailability}
            exact
            layout={DashboardLayout}
            path={ROUTES.AVAILABILITY}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.APPOINTMENTS_PAGE_VISIT}
            component={AsyncAppointments}
            exact
            layout={DashboardLayout}
            path={ROUTES.APPOINTMENTS}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.CART_PAGE_VISIT}
            component={AsyncCart}
            exact
            layout={DashboardLayout}
            path={ROUTES.CART}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.CALENDAR_PAGE_VISIT}
            component={AsyncCalendar}
            exact
            layout={DashboardLayout}
            path={ROUTES.CALENDAR}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.ACCESSORIES_PAGE_VISIT}
            component={AsyncAccessories}
            exact
            layout={DashboardLayout}
            path={ROUTES.ACCESSORIES}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SAMPLES_PAGE_VISIT}
            component={AsyncSamples}
            exact
            layout={DashboardLayout}
            path={ROUTES.SAMPLES}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SAMPLES_PAGE_VISIT}
            component={AsyncSamplesTaskTime}
            exact
            layout={DashboardLayout}
            path={ROUTES.SAMPLES_TASK_TIME}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SAMPLES_PAGE_VISIT}
            component={AsyncSampleDetails}
            exact
            layout={DashboardLayout}
            path={ROUTES.SAMPLE_DETAILS(':sampleId')}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.SAMPLE_NEW_PAGE_VISIT}
            component={AsyncNewSample}
            exact
            layout={DashboardLayout}
            path={ROUTES.SAMPLES_NEW}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.PRODUCTION_PAGE_VISIT}
            component={AsyncProduction}
            exact
            layout={DashboardLayout}
            path={ROUTES.PRODUCTION}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.PRODUCTION_ALL_PAGE_VISIT}
            component={AsyncProductionAll}
            exact
            layout={DashboardLayout}
            path={ROUTES.PRODUCTION_ALL}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.PRODUCTION_SETTINGS_PAGE_VISIT}
            component={AsyncProductionSettings}
            exact
            layout={DashboardLayout}
            path={ROUTES.PRODUCTION_SETTINGS}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.FINANCIALS_PAGE_VISIT}
            component={AsyncPayments}
            exact
            layout={DashboardLayout}
            path={ROUTES.FINANCIALS_PAYMENTS}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.DELIVERY_PAGE_VISIT}
            component={AsyncDelivery}
            exact
            layout={DashboardLayout}
            path={ROUTES.DELIVERY}
            props={childProps}
          />
          <AuthenticatedRoute
            access={ACCESS_ACTION.DELIVERY_PAGE_VISIT}
            component={AsyncDeliveryDetails}
            exact
            layout={DashboardLayout}
            path={ROUTES.DELIVERY_DETAILS(':deliveryId')}
            props={childProps}
          />
          <AppliedRoute component={AsyncNotFound} layout={AuthLayout} props={childProps} />
        </Switch>
        {isModal ? (
          <Switch>
            <AuthenticatedRoute
              access={ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT}
              component={AsyncBrideDetails}
              exact
              layout={ModalLayout}
              path={ROUTES.BRIDES_DETAILS(':brideId')}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT}
              component={AsyncNewBride}
              exact
              layout={ModalLayout}
              path={ROUTES.BRIDES_NEW}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.SUPPLIER_DETAILS_PAGE_VISIT}
              component={AsyncSupplierDetails}
              exact
              layout={ModalLayout}
              path={ROUTES.SUPPLIERS_DETAILS(':supplierId')}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.SUPPLIER_NEW_PAGE_VISIT}
              component={AsyncNewSupplier}
              exact
              layout={ModalLayout}
              path={ROUTES.SUPPLIERS_NEW}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT}
              component={AsyncInventoryDetails}
              exact
              layout={ModalLayout}
              path={ROUTES.INVENTORY_DETAILS(':inventoryId')}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.INVENTORY_NEW_PAGE_VISIT}
              component={AsyncNewInventory}
              exact
              layout={ModalLayout}
              path={ROUTES.INVENTORY_NEW}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.SAMPLES_PAGE_VISIT}
              component={AsyncSampleDetails}
              exact
              layout={ModalLayout}
              path={ROUTES.SAMPLE_DETAILS(':sampleId')}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.SAMPLE_NEW_PAGE_VISIT}
              component={AsyncNewSample}
              exact
              layout={ModalLayout}
              path={ROUTES.SAMPLES_NEW}
              props={childProps}
            />
            <AuthenticatedRoute
              access={ACCESS_ACTION.DELIVERY_PAGE_VISIT}
              component={AsyncDeliveryDetails}
              exact
              layout={ModalLayout}
              path={ROUTES.DELIVERY_DETAILS(':deliveryId')}
              props={childProps}
            />
            <AppliedRoute component={AsyncNotFound} layout={ModalLayout} props={childProps} />
          </Switch>
        ) : null}
      </>
    );
  }
}

Routes.propTypes = {
  childProps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object
  ]).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  })
};

Routes.defaultProps = {
  location: {}
};

export default withRouter(Routes);
