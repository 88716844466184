import Storage from '@aws-amplify/storage';
import * as imageConversion from 'image-conversion';
import { get } from 'lodash';
import omitEmpty from 'omit-empty';
import promiseRetry from 'promise-retry';
import resizeImage from 'resize-image';

import { API_METHOD, RETRY_OPTIONS } from '../constants/aws-api';
import { COMPRESSION_RATIO, FULL_FILE_EXTENSION, MAX_IMAGE_SIZE_PX } from '../constants/common';
import { roles } from '../constants/user-roles';
import { store } from '../index';
import { setRole } from '../redux/account/account.actions';
import { getIdentityJWT } from './aws-authentication';
import AWSConfig from './aws-config';
import apiGatewayClient from './aws-gateway-client';
import { dataURItoBlob, somePromises } from './utilities';

const callApiGateway = apiGatewayClient({
  invokeUrl: AWSConfig.invokeUrl
});

function updateUserRole(data) {
  const accountState = get(store.getState(), 'account', null);
  const roleCurrent = accountState ? accountState.get('role') : roles.guest;
  const roleFromServer = get(data, 'role', roles.guest);
  if (roleCurrent !== roleFromServer) {
    store.dispatch(setRole(roleFromServer));
  }
}

function callAPI(
  methodName,
  params = {},
  body = {},
  additionalParams = {},
  showErrors = true,
  withoutCredentials = false,
  withoutRetry = false
) {
  const additional = { ...additionalParams };
  const requestInfo = {
    methodName,
    params,
    body,
    additionalParams
  };

  return promiseRetry(
    retry =>
      Promise.resolve()
        .then(() => {
          if (withoutCredentials) {
            return null;
          }

          return getIdentityJWT();
        })
        .then(identityJWT => {
          if (!additional.headers) {
            additional.headers = {};
          }
          if (!additional.queryParams) {
            additional.queryParams = {};
          }
          if (identityJWT) {
            additional.headers.Authorization = identityJWT;
          }

          return callApiGateway[methodName](params, body, additional);
        })
        .then(response => {
          // eslint-disable-next-line no-console
          console.info({
            request: requestInfo,
            response
          });

          const data = get(response, 'data', {});
          updateUserRole(data);
          return data;
        })
        .catch(e => {
          if (withoutRetry) {
            throw e;
          } else {
            retry(e);
          }
        }),
    RETRY_OPTIONS
  )
    .then(
      value => value,
      err => {
        console.error({
          request: requestInfo,
          error: err
        });

        if (showErrors) {
          // show error code
        }

        throw get(err, 'data', err);
      }
    )
    .catch(err => {
      console.error({
        request: requestInfo,
        error: err
      });

      if (showErrors) {
        // show error code
      }

      throw err;
    });
}

export function GET(model, params = {}, additional = {}, showErrors) {
  const parameters = { ...params, model };
  return callAPI(API_METHOD.GET, parameters, {}, additional, showErrors);
}

export function POST(model, body = {}, params = {}, additional = {}, showErrors) {
  const parameters = { ...params, model };
  return callAPI(API_METHOD.POST, parameters, body, additional, showErrors);
}

export function PUT(
  model,
  body = {},
  params = {},
  additional = {},
  showErrors,
  isOmitEmpty = true
) {
  const parameters = { ...params, model };
  const bodyObj = isOmitEmpty ? omitEmpty({ ...body }) : { ...body };
  return callAPI(API_METHOD.PUT, parameters, bodyObj, additional, showErrors);
}

export function DELETE(model, body = {}, params = {}, additional = {}, showErrors) {
  const parameters = { ...params, model };
  return callAPI(API_METHOD.DELETE, parameters, body, additional, showErrors);
}

export function PATCH(model, body = {}, params = {}, additional = {}, showErrors) {
  const parameters = { ...params, model };
  return callAPI(API_METHOD.PATCH, parameters, body, additional, showErrors);
}

/**
 * @param {string} key
 * @param {base64} imgData
 * @returns {Promise}
 */
export function uploadImageS3(key, imgData) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imgData;
    image.onload = () => {
      const { width, height } = image;
      const max = width > height ? width : height;
      const scaleFactor = max > MAX_IMAGE_SIZE_PX ? MAX_IMAGE_SIZE_PX / max : 1;
      const newHeight = scaleFactor * height;
      const newWidth = scaleFactor * width;
      const imageFullData = resizeImage.resize(image, newWidth, newHeight, resizeImage.JPEG);
      imageConversion
        .compress(dataURItoBlob(imageFullData), COMPRESSION_RATIO)
        .then(data =>
          Storage.put(`${key}${FULL_FILE_EXTENSION}`, data, {
            contentType: 'image/jpeg',
            cacheControl: 'max-age=604800'
          })
        )
        .then(resolve)
        .catch(reject);
    };
    image.onerror = e => reject(e);
  });
}

export function getImagesS3(imagesToFetch) {
  const getImagePromise = (shortKey, imageKey) =>
    Storage.get(imageKey).then(url => {
      return {
        key: shortKey,
        src: url,
        thumbnail: url
      };
    });

  const promises = [];
  for (let i = 0; i < imagesToFetch.length; i += 1) {
    const img = imagesToFetch[i] || {};
    if (img.key) {
      promises.push(getImagePromise(img.key, `${img.key}${FULL_FILE_EXTENSION}`));
    }
  }
  return somePromises(promises);
}

export function deleteImageS3(imagesToDelete = []) {
  const promises = [];
  for (let i = 0; i < imagesToDelete.length; i += 1) {
    const img = imagesToDelete[i] || {};
    if (img.key) {
      promises.push(Storage.remove(`${img.key}${FULL_FILE_EXTENSION}`));
    }
  }
  return somePromises(promises);
}
