import PropTypes from 'prop-types';
import React from 'react';

function IconPickupReturn(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 -20 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m256.003906 472.003906c-105.914062 0-198.304687-71.539062-224.691406-173.976562-1.101562-4.273438 1.480469-8.640625 5.761719-9.746094 4.238281-1.070312 8.640625 1.480469 9.742187 5.761719 24.554688 95.359375 110.578125 161.960937 209.1875 161.960937 119.101563 0 216-96.898437 216-216 0-4.425781 3.574219-8 8-8 4.421875 0 8 3.574219 8 8 0 127.917969-104.082031 232-232 232zm0 0" />
      <path d="m32.003906 240.003906c-4.425781 0-8-3.578125-8-8 0-127.921875 104.078125-231.99999975 232-231.99999975 105.910156 0 198.300782 71.53515575 224.6875 173.97265575 1.101563 4.273438-1.480468 8.640626-5.761718 9.746094-4.214844 1.113282-8.632813-1.480468-9.742188-5.761718-24.554688-95.359376-110.578125-161.957032-209.183594-161.957032-119.105468 0-216 96.894532-216 216 0 4.421875-3.578125 8-8 8zm0 0" />
      <path d="m32.003906 240.003906c-2.050781 0-4.097656-.785156-5.65625-2.347656l-24-24c-3.128906-3.125-3.128906-8.183594 0-11.308594 3.125-3.128906 8.183594-3.128906 11.308594 0l24 24c3.128906 3.125 3.128906 8.183594 0 11.308594-1.558594 1.5625-3.605469 2.347656-5.652344 2.347656zm0 0" />
      <path d="m32.003906 240.003906c-2.050781 0-4.097656-.785156-5.65625-2.347656-3.128906-3.125-3.128906-8.183594 0-11.308594l24-24c3.125-3.128906 8.183594-3.128906 11.308594 0 3.128906 3.125 3.128906 8.183594 0 11.308594l-24 24c-1.558594 1.5625-3.605469 2.347656-5.652344 2.347656zm0 0" />
      <path d="m504.003906 264.003906c-2.050781 0-4.097656-.785156-5.65625-2.347656l-24-24c-3.128906-3.125-3.128906-8.183594 0-11.308594 3.125-3.128906 8.183594-3.128906 11.308594 0l24 24c3.128906 3.125 3.128906 8.183594 0 11.308594-1.558594 1.5625-3.605469 2.347656-5.652344 2.347656zm0 0" />
      <path d="m456.003906 264.003906c-2.050781 0-4.097656-.785156-5.65625-2.347656-3.128906-3.125-3.128906-8.183594 0-11.308594l24-24c3.125-3.128906 8.183594-3.128906 11.308594 0 3.128906 3.125 3.128906 8.183594 0 11.308594l-24 24c-1.558594 1.5625-3.605469 2.347656-5.652344 2.347656zm0 0" />
      <path d="m111.984375 419.5625c-4.421875 0-8-3.578125-8-8v-203.640625c0-4.425781 3.578125-8 8-8 4.425781 0 8 3.574219 8 8v203.640625c0 4.421875-3.574219 8-8 8zm0 0" />
      <path d="m400.019531 419.578125c-4.425781 0-8-3.574219-8-8v-203.65625c0-4.425781 3.574219-8 8-8 4.421875 0 8 3.574219 8 8v203.65625c0 4.425781-3.578125 8-8 8zm0 0" />
      <path d="m400.019531 215.921875h-288.035156c-4.421875 0-8-3.574219-8-8s3.578125-8 8-8h288.035156c4.421875 0 8 3.574219 8 8s-3.578125 8-8 8zm0 0" />
      <path d="m111.976562 215.921875c-1.398437 0-2.824218-.367187-4.109374-1.144531-3.792969-2.269532-5.015626-7.183594-2.746094-10.976563l24-40.015625c2.265625-3.78125 7.175781-5.015625 10.976562-2.742187 3.792969 2.269531 5.015625 7.183593 2.746094 10.976562l-24 40.015625c-1.496094 2.503906-4.144531 3.886719-6.867188 3.886719zm0 0" />
      <path d="m400.027344 215.921875c-2.722656 0-5.371094-1.382813-6.867188-3.878906l-24-40.015625c-2.269531-3.792969-1.046875-8.707032 2.746094-10.976563 3.792969-2.273437 8.703125-1.039062 10.976562 2.742188l24 40.015625c2.269532 3.792968 1.046876 8.707031-2.746093 10.976562-1.292969.769532-2.710938 1.136719-4.109375 1.136719zm0 0" />
      <path d="m376.019531 175.90625h-240.035156c-4.421875 0-8-3.578125-8-8 0-4.425781 3.578125-8 8-8h240.035156c4.421875 0 8 3.574219 8 8 0 4.421875-3.578125 8-8 8zm0 0" />
      <path d="m223.992188 215.921875c-.511719 0-1.046876-.046875-1.574219-.160156-4.328125-.863281-7.144531-5.078125-6.273438-9.414063l8-40.019531c.859375-4.324219 5.035157-7.175781 9.417969-6.269531 4.328125.863281 7.144531 5.078125 6.273438 9.414062l-8 40.015625c-.761719 3.808594-4.105469 6.433594-7.84375 6.433594zm0 0" />
      <path d="m287.992188 215.921875c-3.734376 0-7.078126-2.625-7.832032-6.433594l-8-40.015625c-.863281-4.335937 1.945313-8.542968 6.273438-9.414062 4.390625-.898438 8.550781 1.945312 9.417968 6.269531l8 40.019531c.863282 4.335938-1.945312 8.542969-6.273437 9.414063-.535156.113281-1.070313.160156-1.585937.160156zm0 0" />
      <path d="m223.984375 263.921875c-4.421875 0-8-3.574219-8-8v-48c0-4.425781 3.578125-8 8-8 4.425781 0 8 3.574219 8 8v48c0 4.425781-3.574219 8-8 8zm0 0" />
      <path d="m288.003906 263.921875c-4.425781 0-8-3.574219-8-8v-48c0-4.425781 3.574219-8 8-8 4.421875 0 8 3.574219 8 8v48c0 4.425781-3.578125 8-8 8zm0 0" />
      <path d="m288.011719 263.921875c-.640625 0-1.296875-.078125-1.945313-.238281l-32-8c-4.289062-1.074219-6.894531-5.417969-5.824218-9.699219 1.0625-4.292969 5.398437-6.910156 9.695312-5.824219l32 8c4.289062 1.074219 6.898438 5.417969 5.824219 9.699219-.910157 3.648437-4.167969 6.0625-7.75 6.0625zm0 0" />
      <path d="m223.976562 263.921875c-3.582031 0-6.839843-2.425781-7.75-6.0625-1.082031-4.28125 1.527344-8.625 5.824219-9.699219l32.015625-8c4.273438-1.09375 8.625 1.53125 9.695313 5.824219 1.082031 4.28125-1.527344 8.625-5.824219 9.699219l-32.015625 8c-.648437.160156-1.304687.238281-1.945313.238281zm0 0" />
      <path d="m328.035156 359.9375h-144.066406c-4.421875 0-8-3.574219-8-8 0-4.421875 3.578125-8 8-8h144.066406c4.421875 0 8 3.578125 8 8 0 4.425781-3.578125 8-8 8zm0 0" />
      <path d="m328.035156 328.003906h-144.066406c-4.421875 0-8-3.578125-8-8 0-4.425781 3.578125-8 8-8h144.066406c4.421875 0 8 3.574219 8 8 0 4.421875-3.578125 8-8 8zm0 0" />
      <path d="m288.019531 391.953125h-64.035156c-4.421875 0-8-3.574219-8-8 0-4.421875 3.578125-8 8-8h64.035156c4.421875 0 8 3.578125 8 8 0 4.425781-3.578125 8-8 8zm0 0" />
    </svg>
  );
}

IconPickupReturn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconPickupReturn.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconPickupReturn;
