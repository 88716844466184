import { Record } from 'immutable';

import { roles } from '../../constants/user-roles';

export const actionTypes = {
  ACCOUNT_SET_AUTHENTICATED_USER: 'ACCOUNT_SET_AUTHENTICATED_USER',
  ACCOUNT_DELETE_AUTHENTICATED_USER: 'ACCOUNT_DELETE_AUTHENTICATED_USER',
  ACCOUNT_SET_USER_ATTRIBUTES: 'ACCOUNT_SET_USER_ATTRIBUTES',
  ACCOUNT_SET_USER_ROLE: 'ACCOUNT_SET_USER_ROLE',
  ACCOUNT_SET_PROFILE: 'ACCOUNT_SET_PROFILE'
};

export const InitialState = Record(
  {
    authenticatedUser: null,
    isAuthenticated: false,
    isAuthenticating: true,
    userAttributes: {},
    profile: {},
    role: roles.initial
  },
  'accountRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_SET_AUTHENTICATED_USER: {
      return state.merge({
        authenticatedUser: action.user,
        isAuthenticated: true,
        isAuthenticating: false
      });
    }
    case actionTypes.ACCOUNT_DELETE_AUTHENTICATED_USER: {
      return state.merge({
        authenticatedUser: null,
        isAuthenticated: false,
        isAuthenticating: false,
        userAttributes: {},
        role: roles.initial
      });
    }
    case actionTypes.ACCOUNT_SET_USER_ATTRIBUTES:
      return state.merge({
        userAttributes: action.userAttributes
      });
    case actionTypes.ACCOUNT_SET_USER_ROLE:
      return state.set('role', action.role);
    case actionTypes.ACCOUNT_SET_PROFILE:
      return state.set('profile', action.profile);
    default:
      return state;
  }
};
