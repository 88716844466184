// eslint-disable-next-line import/prefer-default-export
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js';
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max';
import { get, isEqual, isObject, transform } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import XLSX from 'xlsx';

import americanStates from '../constants/american-states';
import { NOTES_MAX_LENGTH, SORTING_ORDER, TAX, TELEPHONE_FORMAT_SERVER } from '../constants/common';
import errorMessages from '../constants/validation-messages/import-error.messages';

const statesList = americanStates.map(el => el.abbreviation);

/* iOS fix - start */
export const onModalOpen = () => {
  const scrollY = window.pageYOffset || 0;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
};

export const onModalClose = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};
/* iOS fix - end */

export function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatMask(mask, number) {
  const s = typeof number === 'number' ? `${number}` : number.replace(/[^0-9]/g, '');
  let r = '';

  for (let im = 0, is = 0; im < mask.length && is < s.length; im += 1) {
    // eslint-disable-next-line no-plusplus
    r += mask.charAt(im) === 'X' ? s.charAt(is++) : mask.charAt(im);
  }

  return r;
}

export function reduceString(text, maxLength = NOTES_MAX_LENGTH, noValue = '---') {
  const str = text && typeof text === 'string' ? text : noValue;

  return str.length > maxLength ? `${text.substr(0, maxLength)}...` : str;
}

export function isIOS() {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  // eslint-disable-next-line no-extra-boolean-cast
  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
}

export function isFullscreenMode() {
  return (
    window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true
  );
}

export function isIOSFullscreen() {
  return isIOS() && isFullscreenMode();
}

export function deepset(object, path, value) {
  const obj = { ...object };
  if (path.indexOf('.') === -1) {
    obj[path] = value;
    return obj;
  }
  const parts = path.split('.');
  if (!obj[parts[0]]) {
    obj[parts[0]] = {};
  }
  return deepset(obj[parts[0]], parts.slice(1).join('.'), value);
}

export function workbookToObject(wb) {
  const out = {};
  /* assign one-off keys */
  // eslint-disable-next-line no-underscore-dangle
  const ws = wb.Sheets._keys;
  if (ws) {
    const data = XLSX.utils.sheet_to_json(ws, { raw: true });
    data.forEach(r => {
      deepset(out, r.path, r.value);
    });
  }

  /* assign arrays from worksheet tables */
  wb.SheetNames.forEach(n => {
    if (n === '_keys') {
      return;
    }
    out[n] = XLSX.utils.sheet_to_json(wb.Sheets[n], { raw: true });
  });

  return out;
}

// ['[168].phone'] --> [168]
export function parseYupErrorLines(inner = []) {
  const result = [];
  inner.forEach(el => {
    const path = get(el, 'path', '');
    const arr = path.match(/[[]\d{1,4}[\]]/gi);
    const str = Array.isArray(arr) && arr[0] ? arr[0] : '';
    const num = str.length >= 3 ? +str.substring(1, str.length - 1) : null;
    if (typeof num === 'number') {
      result.push(num);
    }
  });
  return result;
}

export function isObjectEmpty(obj) {
  if (!obj) {
    return true;
  }
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function getRandomId() {
  return Math.random().toString(36).substring(7);
}

export function getUniqId(size = 75) {
  return nanoid(size);
}

export function makeCancelable(promise) {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      // eslint-disable-next-line prefer-promise-reject-errors
      .then(val => (isCanceled ? reject({ isCanceled }) : resolve(val)))
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(error => (isCanceled ? reject({ isCanceled }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    }
  };
}

/**
 * @param {*[]} list
 * @param {boolean} toLowerCase
 * @returns {{label: string, value: string}[]} Array of options
 */
export function generateDropdownOptions(list, toLowerCase = false) {
  const arr = list || [];
  return arr.map(item => {
    if (item.value && item.label) {
      return item;
    }
    const val = item || '';
    return {
      value: toLowerCase ? val.toLowerCase() : val,
      label: val
    };
  });
}

export function findDropdownValue(list, value) {
  if (!list || !Array.isArray(list)) {
    return '';
  }

  const listLength = list.length;

  for (let i = 0; i < listLength; i += 1) {
    if (list[i].value === value) {
      return list[i];
    }
  }

  return '';
}

export function roundDate(date, duration, method) {
  return moment(Math[method](+date / +duration) * +duration);
}

export function prepareIntervals(
  momentStart,
  momentEnd,
  intervalMin = 15,
  roundMin = 15,
  useEndLimit = false
) {
  const intervals = [];

  if (typeof intervalMin !== 'number' || momentEnd.diff(momentStart) < 0) {
    return intervals;
  }

  const startIntervalTime = roundDate(momentStart, moment.duration(roundMin, 'minutes'), 'ceil');
  const endIntervalTime = startIntervalTime.clone().add(intervalMin, 'minutes');
  const limitTime = useEndLimit ? endIntervalTime : startIntervalTime;

  while (limitTime < momentEnd) {
    intervals.push({
      start: startIntervalTime.format(),
      end: endIntervalTime.format()
    });
    startIntervalTime.add(intervalMin, 'minutes');
    endIntervalTime.add(intervalMin, 'minutes');
  }

  return intervals;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

export function mapEvents(list) {
  return list.map(e => {
    const color = e.readOnly ? '#969c9e' : '#2c7be5';
    const location = e.location || '';
    const type = e.type || '';
    const ownerName = e.owner_name || '';
    const title = e.readOnly ? `${location} [${type}] ${ownerName}` : `${location}`;

    return {
      start: moment(e.from, 'X').format(),
      end: moment(e.to, 'X').format(),
      groupId: e.series_id || null,
      title,
      data: e,
      color,
      textColor: '#FFFFFF'
    };
  });
}
export function mapAccessories(list, fieldToShow) {
  return list.map(e => {
    // const { name, location, shoes, headpiece, sales_contact_name: salesContactName } = e;
    const { shoes, headpiece } = e;
    const color = '#2c7be5';

    // const title = `${shoes ? `Shoes: ${shoes} • ` : ''} ${headpiece ? `Headpiece: ${headpiece} • ` : ''} ${location} • ${name} • ${salesContactName || ''}`;
    const title = fieldToShow
      ? get(e, fieldToShow)
      : `${shoes ? ` - ${shoes}` : ''} ${headpiece ? ` - ${headpiece}` : ''}`;

    return {
      start: moment(e.from, 'X').startOf('day').format(),
      end: moment(e.to, 'X').endOf('day').format(),
      title,
      data: e,
      color,
      textColor: '#FFFFFF'
    };
  });
}

export const compareText = (propName, order) => (a, b) => {
  const x = a[propName] ? +a[propName] || String(a[propName]).toLowerCase() : '';
  const y = b[propName] ? +b[propName] || String(b[propName]).toLowerCase() : '';
  if (x < y) return order === SORTING_ORDER.AZ ? -1 : 1;
  if (x > y) return order === SORTING_ORDER.AZ ? 1 : -1;
  return 0;
};

export const compareTextUseDecorator = (funcName, order, decoratorArg) => (a, b) => {
  const aVal = typeof a[funcName] === 'function' ? a[funcName](decoratorArg) : '';
  const bVal = typeof b[funcName] === 'function' ? b[funcName](decoratorArg) : '';
  const x =
    typeof aVal === 'string' || typeof aVal === 'number' ? +aVal || String(aVal).toLowerCase() : '';
  const y =
    typeof bVal === 'string' || typeof bVal === 'number' ? +bVal || String(bVal).toLowerCase() : '';
  if (x < y) return order === SORTING_ORDER.AZ ? -1 : 1;
  if (x > y) return order === SORTING_ORDER.AZ ? 1 : -1;
  return 0;
};

export const generate2YearsOptions = (minMoment = null) => {
  const initMonth = moment()
    .set({ date: 1, hour: 10, minute: 0, second: 0, millisecond: 0 })
    .subtract(12, 'months');
  const options = [];

  for (let i = 0; i < 25; i += 1) {
    const month = initMonth.clone().add(i, 'months');

    if (!minMoment || month.isSameOrAfter(minMoment)) {
      options.push({
        value: +month.format('X'),
        label: month.format('YYYY-MMMM')
      });
    }
  }

  return options;
};

/**
 * @param {number} value
 * @return {number}
 */
export const calculateTax = value => {
  return (value * TAX) / 100;
};

/**
 *
 * @param dataURI
 * @returns {Blob}
 */
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function somePromises(promises, count = 1) {
  const wrapped = promises.map(promise =>
    promise.then(
      value => ({ success: true, value }),
      () => ({ success: false })
    )
  );
  return Promise.all(wrapped).then(results => {
    const successful = results.filter(result => result.success);
    if (successful.length < count) {
      throw new Error(`Only ${successful.length} resolved.`);
    }

    return successful.map(result => result.value);
  });
}

export function errorMsg(value, fieldName, type) {
  const matches = value.path.match(/\[(.*?)]/);
  const lineNumber = parseInt(matches[1], 10) + 1;
  switch (type) {
    case 'required': {
      return errorMessages.required(fieldName, lineNumber);
    }
    case 'mismatch': {
      return errorMessages.mismatch(fieldName, lineNumber);
    }
    case 'oneof': {
      return errorMessages.oneOf(fieldName, statesList, lineNumber);
    }
    default:
      return errorMessages.default(fieldName, lineNumber);
  }
}

export function convertToString(num, defaultValue = '') {
  if (typeof num === 'number') {
    return num.toString();
  }

  if (num && typeof num === 'string') {
    return num;
  }

  return defaultValue;
}

export const isPhoneNumberValid = value => {
  if (!value) {
    return true;
  }
  const phoneNumber = value || '';
  const phoneNumberParsed = parseMax(`+${phoneNumber}`);
  return phoneNumberParsed ? phoneNumberParsed.isValid() : false;
};

export const formatServerPhoneNumberToReadable = value => {
  if (!value) {
    return '';
  }
  const phoneNumber = parseMin(value);
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.formatInternational().slice(1);
};

export const formatReadablePhoneNumberToServer = value => {
  if (!value) {
    return '';
  }

  const parsedValue = parseMin(`+${value}`);
  if (!parsedValue) {
    return '';
  }

  return parsedValue.format(TELEPHONE_FORMAT_SERVER);
};

export const rotateBase64Image90deg = (base64Image, isClockwise = true) => {
  return new Promise((resolve, reject) => {
    const offScreenCanvas = document.createElement('canvas');
    const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      const { width, height } = image;

      offScreenCanvas.height = width;
      offScreenCanvas.width = height;

      if (isClockwise) {
        offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
      } else {
        offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
        offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
      }
      offScreenCanvasCtx.drawImage(image, 0, 0);

      resolve(offScreenCanvas.toDataURL('image/jpeg', 100));
    };
    image.onerror = e => reject(e);
  });
};

export const formatDate = (dateUnixTimestamp, format = 'MM/DD/YYYY') => {
  const dateMoment = moment(dateUnixTimestamp, 'X');

  if (dateMoment.isValid()) {
    return dateMoment.format(format);
  }

  return '---';
};
export const formatTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) % 60;
  return [hours, minutes].map(v => (v < 10 ? `0${v}` : v)).join(':');
};

export const formatMoney = amount => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

export function uniqueBy(a, cond) {
  return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i);
}

/**
 * @param {BrideDecorator[]} bridesList
 */
export function exportBridesToCsv(bridesList) {
  const rows = bridesList.map(bride => [
    'Ms.',
    bride.getFirstName(),
    bride.getLastName(),
    bride.getAddressStreet(),
    '',
    bride.getAddressCity(),
    bride.getAddressState(),
    bride.getAddressZip()
  ]);
  const arrayContent = [
    [
      'Salutation',
      'First Name',
      'Last Name',
      'Street Address',
      'Street Address Line 2',
      'City',
      'State',
      'Zip Code'
    ],
    ...rows
  ];
  const csvContent = arrayContent.join('\n');
  const link = window.document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(`${csvContent}`)}`);
  link.setAttribute('download', `brides_${moment().format('X')}.csv`);
  link.click();
  link.remove();
}
