import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Main from '../components/Main/Main';

function AuthLayout({ children }) {
  return (
    <Main className="h-100 w-100">
      <Container className="h-100">
        <Row className="h-100">
          <Col className="mx-auto d-table h-100" lg="6" md="8" sm="10">
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
      </Container>
    </Main>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default AuthLayout;
