import { Record } from 'immutable';
import moment from 'moment';

import { isObjectEmpty } from '../../services/utilities';

export const actionTypes = {
  CART_SET_LIST: 'CART_SET_LIST',
  CART_DELETE_ITEM: 'CART_DELETE_ITEM',
  CART_UPDATE_ITEM: 'CART_UPDATE_ITEM'
};

export const InitialState = Record(
  {
    list: [],
    lastEvaluatedKey: null,
    lastUpdate: null
  },
  'cartRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CART_SET_LIST: {
      return state.merge({
        list: action.list,
        lastEvaluatedKey: action.lastEvaluatedKey,
        lastUpdate: moment()
      });
    }
    case actionTypes.CART_DELETE_ITEM: {
      const { id } = action;
      const updatedList = state.get('list').filter(el => el.id !== id);

      return state.set('list', updatedList);
    }
    case actionTypes.CART_UPDATE_ITEM: {
      const list = state.get('list');
      const { data, id } = action;
      const noData = isObjectEmpty(data);
      let updatedList = [];

      if (noData) {
        updatedList = list.filter(el => el.id !== id);
      } else {
        updatedList = list.map(el => {
          if (el.id === id) {
            return { ...data };
          }
          return el;
        });
      }

      return state.set('list', updatedList);
    }
    default:
      return state;
  }
};
