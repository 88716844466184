import cn from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { hideSidebar } from '../../redux/ui/ui.actions';

const SidebarItem = withRouter(
  ({
    subItem,
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    location,
    to,
    hideSidebar: hideNavSidebar,
    isDisabled
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? 'active' : '';
    };

    const iconSize = subItem ? 22 : 24;
    return (
      <li className={`sidebar-item ${getSidebarItemClass(to)}`} onClick={hideNavSidebar}>
        <NavLink
          activeClassName="active"
          className={cn('sidebar-link', {
            'ml-4': subItem,
            'font-weight-normal': subItem,
            'text-92': subItem
          })}
          onClick={isDisabled ? e => e.preventDefault() : null}
          to={to}
        >
          {Icon ? (
            <Icon className="align-middle mr-3" size={iconSize} />
          ) : (
            <span className="align-middle mr-3 d-inline-block" style={{width: iconSize}} />
          )}
          {name}
          {badgeColor && badgeText ? (
            <Badge className="sidebar-badge" color={badgeColor} size={18}>
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const mapDispatchToProps = {
  hideSidebar
};

export default connect(null, mapDispatchToProps)(SidebarItem);
