import { actionTypes } from './account.reducer';

export function setAuthenticatedUser(currentAuthenticatedUser) {
  return {
    type: actionTypes.ACCOUNT_SET_AUTHENTICATED_USER,
    user: currentAuthenticatedUser
  };
}

export function deleteAuthenticatedUser() {
  return {
    type: actionTypes.ACCOUNT_DELETE_AUTHENTICATED_USER
  };
}

export function setUserAttributes(userAttributes) {
  return {
    type: actionTypes.ACCOUNT_SET_USER_ATTRIBUTES,
    userAttributes
  };
}

export function setRole(role) {
  return {
    type: actionTypes.ACCOUNT_SET_USER_ROLE,
    role
  };
}

export function setProfile(profile) {
  return {
    type: actionTypes.ACCOUNT_SET_PROFILE,
    profile
  };
}
