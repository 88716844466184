import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';

import API_MESSAGE from '../../constants/api-messages';
import { API_MODEL } from '../../constants/aws-api';
import * as AwsAPI from '../../services/aws-api';
import * as AwsAuthentication from '../../services/aws-authentication';
import AWSConfig from '../../services/aws-config';
import { hideGlobalSpinner, showGlobalSpinner } from '../ui/ui.actions';
import * as accountActions from './account.actions';

export function loadBaseUserData() {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return Promise.all([AwsAuthentication.getUserAttributes(), AwsAPI.GET(API_MODEL.INIT)])
      .then(responses => {
        const userAttributes = responses[0];
        const initDate = responses[1];
        const warningMessage = get(initDate, 'message', '');
        dispatch(hideGlobalSpinner());
        dispatch(accountActions.setUserAttributes(userAttributes));
        if (warningMessage) {
          toastr.warning(warningMessage);
        }
        return userAttributes;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function updateUserAttributes(attributes) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.updateUserAttributes(attributes)
      .then(() => AwsAuthentication.getUserAttributes())
      .then(userAttributes => {
        dispatch(hideGlobalSpinner());
        toastr.success(API_MESSAGE.SAVE_CHANGES_SUCCESS);
        dispatch(accountActions.setUserAttributes(userAttributes));
        return userAttributes;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function registerNewUser(username, password, firstName, lastName, email, cell) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.signUp(username, password, firstName, lastName, email, cell)
      .then(data => {
        dispatch(hideGlobalSpinner());
        return data;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function confirmUserRegistration(username, code) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.confirmSignUp(username, code)
      .then(data => {
        dispatch(hideGlobalSpinner());
        return data;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function authorizeUser(username, password) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.signIn(username, password)
      .then(currentAuthenticatedUser => {
        dispatch(accountActions.setAuthenticatedUser(currentAuthenticatedUser));
        return dispatch(loadBaseUserData());
      })
      .then(() => dispatch(hideGlobalSpinner()))
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
      });
  };
}

export function restoreAccountData() {
  return dispatch => {
    return AwsAuthentication.getCurrentAuthenticatedUser()
      .then(currentAuthenticatedUser => {
        dispatch(accountActions.setAuthenticatedUser(currentAuthenticatedUser));
        return dispatch(loadBaseUserData());
      })
      .catch(() => dispatch(accountActions.deleteAuthenticatedUser()));
  };
}

export function logOut() {
  return dispatch => {
    return AwsAuthentication.signOut()
      .then(() => dispatch(accountActions.deleteAuthenticatedUser()))
      .catch(e => {
        toastr.error(e.message || API_MESSAGE.UNEXPECTED);
      });
  };
}

export function resetPassword(username) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.forgotPassword(username)
      .then(data => {
        dispatch(hideGlobalSpinner());
        return data;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function saveNewPassword(username, code, password) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.forgotPasswordSubmit(username, code, password)
      .then(data => {
        dispatch(hideGlobalSpinner());
        return data;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function resendEmailVerificationCode() {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.resendVerificationCode('email')
      .then(() => {
        dispatch(hideGlobalSpinner());
        toastr.success(API_MESSAGE.VERIFICATION_CODE_SENT);
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
      });
  };
}

export function confirmEmail(code) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAuthentication.confirmVerificationCode('email', code)
      .then(() => {
        dispatch(hideGlobalSpinner());
        toastr.success(API_MESSAGE.EMAIL_CONFIRMED);
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function getAddressByZip(zipCode) {
  return () => {
    const additionalParams = {
      queryParams: { id: 'ZIP', list: zipCode }
    };
    return AwsAPI.GET(API_MODEL.SETTINGS, {}, additionalParams)
      .then(data => {
        const result = get(data, 'object', {});
        return {
          city: '',
          country: '',
          lat: '',
          long: '',
          state: '',
          zip: '',
          ...result
        };
      })
      .catch(e => {
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function uploadImage(imgKey, imgData, bucket = null) {
  return () => {
    return AwsAPI.uploadImageS3(imgKey, imgData, bucket)
      .then(image => {
        const full = image || {};
        return {
          index: imgKey,
          fullKey: full.Key,
          fullUrl: full.Location,
          thumbnailKey: full.Key,
          thumbnailUrl: full.Location
        };
      })
      .catch(e => {
        throw e;
      });
  };
}

export function uploadImageList(images) {
  return dispatch => {
    const promises = [];
    for (let i = 0; i < images.length; i += 1) {
      const image = images[i];
      if (image.base64 && image.key && image.bucket && image.src) {
        promises.push(dispatch(uploadImage(image.key, image.src, image.bucket)));
      }
    }
    if (promises.length > 0) {
      return Promise.all(promises);
    }
    return Promise.resolve();
  };
}

export function deleteImageList(images) {
  return () => {
    if (Array.isArray(images) && images.length === 0) {
      return Promise.resolve();
    }

    return AwsAPI.deleteImageS3(images, AWSConfig.s3bucket);
  };
}

export function getProfile() {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAPI.GET(API_MODEL.PROFILE, {})
      .then(data => {
        const result = get(data, 'object', {});
        dispatch(hideGlobalSpinner());
        dispatch(accountActions.setProfile(result));
        return result;
      })
      .catch(e => {
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}

export function updateProfile(values) {
  return dispatch => {
    dispatch(showGlobalSpinner());
    return AwsAPI.PATCH(API_MODEL.PROFILE, { ...values })
      .then(data => {
        const result = get(data, 'object', {});
        dispatch(hideGlobalSpinner());
        dispatch(accountActions.setProfile(result));
        return result;
      })
      .catch(e => {
        dispatch(hideGlobalSpinner());
        toastr.error(API_MESSAGE.SERVER_ERROR_TITLE, e.message || API_MESSAGE.UNEXPECTED);
        throw e;
      });
  };
}
