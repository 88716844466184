import PropTypes from 'prop-types';
import React from 'react';

function Content({ children }) {
  return <div className="content">{children}</div>;
}

Content.propTypes = {
  children: PropTypes.element.isRequired
};

export default Content;
