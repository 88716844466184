import PropTypes from 'prop-types';
import React from 'react';

function IconProductionAll(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      viewBox="0 0 256 256"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
        <path
          d="M1152 2539 c-70 -27 -145 -103 -169 -169 l-18 -50 -142 0 -143 0 0
-100 0 -100 -127 0 c-144 0 -176 -11 -213 -72 -20 -32 -20 -53 -20 -988 0
-935 0 -956 20 -988 11 -18 34 -41 52 -52 32 -19 52 -20 888 -20 836 0 856 1
888 20 18 11 41 34 52 52 20 32 20 53 20 988 0 935 0 956 -20 988 -37 61 -69
72 -212 72 l-128 0 0 100 0 100 -143 0 -142 0 -18 50 c-24 68 -99 142 -172
169 -74 28 -180 28 -253 0z m208 -172 c47 -31 69 -73 76 -145 l7 -62 138 0
139 0 0 -120 0 -120 -440 0 -440 0 0 120 0 120 139 0 138 0 7 62 c7 72 29 114
76 145 45 31 115 31 160 0z m-680 -507 l0 -100 600 0 600 0 0 100 0 100 100 0
100 0 0 -900 0 -900 -800 0 -800 0 0 900 0 900 100 0 100 0 0 -100z"
        />
        <path
          d="M1220 2300 c-13 -13 -20 -33 -20 -60 0 -53 27 -80 80 -80 27 0 47 7
60 20 13 13 20 33 20 60 0 27 -7 47 -20 60 -13 13 -33 20 -60 20 -27 0 -47 -7
-60 -20z"
        />
        <path
          d="M1404 1130 l-291 -328 -124 109 c-68 59 -129 108 -135 109 -17 0
-118 -120 -108 -129 51 -44 344 -299 360 -313 l22 -19 343 386 c189 213 345
392 347 399 2 10 -109 117 -120 116 -2 -1 -134 -149 -294 -330z"
        />
      </g>
    </svg>
  );
}

IconProductionAll.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconProductionAll.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconProductionAll;
