import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';

import { onModalClose, onModalOpen } from '../services/utilities';

class ModalLayout extends PureComponent {
  render() {
    return (
      <Modal
        isOpen
        onEnter={onModalOpen}
        onExit={onModalClose}
        size="lg"
        wrapClassName="modal-layout-wrapper"
      >
        <ModalBody className="m-3">{this.props.children}</ModalBody>
      </Modal>
    );
  }
}

ModalLayout.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default withRouter(ModalLayout);
