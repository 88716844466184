import ACCESS_ACTION from './access-actions';

const rules = {
  guest: {
    static: [ACCESS_ACTION.DASHBOARD_PAGE_VISIT, ACCESS_ACTION.PROFILE_PAGE_VISIT]
  },
  presales: {
    static: [
      ACCESS_ACTION.DASHBOARD_PAGE_VISIT,
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PAYMENT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_INTEREST_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_SHOULD_FILL_IN_ENGAGEMENT_MONTH,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_DETAILS_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REMOVE_APPOINTMENT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ENGAGEMENT_WEDDING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ADDRESS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_CONTACT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REFERRALS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_INTEREST,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_MEASUREMENTS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PICKUP,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_IMAGES,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_SECTIONS_UNCOLLAPSED,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_ADDRESS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_CONTACT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_REFERRALS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PICKUP_VISIT
    ]
  },
  sales: {
    static: [
      ACCESS_ACTION.DASHBOARD_PAGE_VISIT,
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.BRIDES_PAGE_STATISTICS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PAYMENT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_INTEREST_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_EXTENDED,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_OWN_APPOINTMENT_CALENDAR,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REMOVE_APPOINTMENT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ENGAGEMENT_WEDDING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ADDRESS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_CONTACT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REFERRALS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_INTEREST,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_MEASUREMENTS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PICKUP,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_IMAGES,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_ADD,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING_PRODUCTION,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_FITTING_PRODUCTION_CLOSE_ALL,
      ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.AVAILABILITY_PAGE_VISIT,
      ACCESS_ACTION.APPOINTMENTS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_ADDRESS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_CONTACT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_REFERRALS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PICKUP_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VIEW_SALES_OWNER,
      ACCESS_ACTION.DELIVERY_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_EDIT_SCHEDULE,
      ACCESS_ACTION.DELIVERY_PAGE_SHOW_PICKUP_RETURN_FULL
    ]
  },
  management: {
    static: [
      ACCESS_ACTION.DASHBOARD_PAGE_VISIT,
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.TEAM_USERS_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PRIORITY_VISIT,
      ACCESS_ACTION.SUPPLIERS_PAGE_VISIT,
      ACCESS_ACTION.SUPPLIER_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.SUPPLIER_NEW_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_GOWN_EDIT,
      ACCESS_ACTION.INVENTORY_DETAILS_SUPPLIER_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_OWNER_HISTORY_VISIT,
      ACCESS_ACTION.INVENTORY_NEW_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_STATISTICS,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_EXTENDED,
      ACCESS_ACTION.BRIDES_PAGE_LIST_COLUMNS_LIMITED,
      ACCESS_ACTION.BRIDES_IMPORT_VISIT,
      ACCESS_ACTION.BRIDES_TASK_TIME_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_INTEREST_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_EXTENDED,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PRODUCTION_OWNER,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_DETAILS_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_ADD,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ROYALTY_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REMOVE_APPOINTMENT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ENGAGEMENT_WEDDING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ADDRESS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_CONTACT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REFERRALS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_INTEREST,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_MEASUREMENTS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PICKUP,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_IMAGES,
      ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT,
      ACCESS_ACTION.CALENDAR_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.ACCESSORIES_PAGE_FILTER_EXTENDED,
      ACCESS_ACTION.CART_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_LIST_DELETE,
      ACCESS_ACTION.PRODUCTION_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING_PRODUCTION,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_FITTING_PRODUCTION_CLOSE_ALL,
      ACCESS_ACTION.PRODUCTION_ALL_PAGE_VISIT,
      ACCESS_ACTION.PRODUCTION_SETTINGS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_ADDRESS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_CONTACT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_REFERRALS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PICKUP_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_VISIT,
      ACCESS_ACTION.SAMPLE_NEW_PAGE_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_LIST_DELETE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VIEW_SALES_OWNER,
      ACCESS_ACTION.DELIVERY_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_EDIT_SCHEDULE,
      ACCESS_ACTION.DELIVERY_PAGE_SHOW_PICKUP_RETURN_FULL,
      ACCESS_ACTION.DELIVERY_PAGE_DRIVER_NOTES_EDIT,
      ACCESS_ACTION.DELIVERY_PAGE_DELIVERY_NOTES_EDIT
    ]
  },
  owner: {
    static: [
      ACCESS_ACTION.DASHBOARD_PAGE_VISIT,
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.TEAM_USERS_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PRIORITY_VISIT,
      ACCESS_ACTION.SUPPLIERS_PAGE_VISIT,
      ACCESS_ACTION.SUPPLIER_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.SUPPLIER_NEW_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_GOWN_EDIT,
      ACCESS_ACTION.INVENTORY_DETAILS_SUPPLIER_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_OWNER_HISTORY_VISIT,
      ACCESS_ACTION.INVENTORY_NEW_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_STATISTICS,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_EXTENDED,
      ACCESS_ACTION.BRIDES_PAGE_LIST_COLUMNS_LIMITED,
      ACCESS_ACTION.BRIDES_IMPORT_VISIT,
      ACCESS_ACTION.BRIDES_TASK_TIME_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PAYMENT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_INTEREST_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_EXTENDED,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PRODUCTION_OWNER,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_DETAILS_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_ADD,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ROYALTY_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REMOVE_APPOINTMENT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ENGAGEMENT_WEDDING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_ADDRESS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_CONTACT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_REFERRALS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_INTEREST,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_MEASUREMENTS,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PICKUP,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_IMAGES,
      ACCESS_ACTION.BRIDE_NEW_PAGE_VISIT,
      ACCESS_ACTION.CALENDAR_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.ACCESSORIES_PAGE_FILTER_EXTENDED,
      ACCESS_ACTION.CART_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_LIST_DELETE,
      ACCESS_ACTION.PRODUCTION_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING_PRODUCTION,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_FITTING_PRODUCTION_CLOSE_ALL,
      ACCESS_ACTION.PRODUCTION_ALL_PAGE_VISIT,
      ACCESS_ACTION.PRODUCTION_SETTINGS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_ADDRESS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_CONTACT_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_REFERRALS_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PICKUP_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_VISIT,
      ACCESS_ACTION.SAMPLE_NEW_PAGE_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_LIST_DELETE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VIEW_SALES_OWNER,
      ACCESS_ACTION.FINANCIALS_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_EDIT_SCHEDULE,
      ACCESS_ACTION.DELIVERY_PAGE_SHOW_PICKUP_RETURN_FULL
    ]
  },
  production: {
    static: [
      ACCESS_ACTION.DASHBOARD_PAGE_VISIT,
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_VISIT,
      ACCESS_ACTION.BRIDES_PAGE_FILTER_BASIC,
      ACCESS_ACTION.BRIDES_PRODUCTION_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_EXTENDED,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_PAYMENT_DETAILS_WRITE,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_PICKUP_VISIT,
      ACCESS_ACTION.BRIDE_DETAILS_PAGE_EDIT_FITTING_PRODUCTION,
      ACCESS_ACTION.PRODUCTION_PAGE_VISIT,
      ACCESS_ACTION.PRODUCTION_SETTINGS_PAGE_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_VISIT,
      ACCESS_ACTION.SAMPLES_PAGE_LIST_DELETE
    ]
  },
  delivery: {
    static: [
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_DELIVERY_EDIT,
      ACCESS_ACTION.DELIVERY_PAGE_DELIVERY_NOTES_EDIT,
      ACCESS_ACTION.DELIVERY_PAGE_CUSTOMER_ACCEPT,
      ACCESS_ACTION.DELIVERY_PAGE_ACCEPT_RETURN,
      ACCESS_ACTION.DELIVERY_PAGE_CUSTOMER_ACCEPT_PICKUP,
      ACCESS_ACTION.DELIVERY_PAGE_VIEW_SCHEDULE,
      ACCESS_ACTION.INVENTORY_PAGE_VISIT,
      ACCESS_ACTION.INVENTORY_DETAILS_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_VISIT,
      ACCESS_ACTION.ACCESSORIES_PAGE_DETAILS_DELIVERY
    ]
  },
  driver: {
    static: [
      ACCESS_ACTION.PROFILE_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_VISIT,
      ACCESS_ACTION.DELIVERY_PAGE_DRIVER_NOTES_EDIT,
      ACCESS_ACTION.DELIVERY_PAGE_DRIVER_EDIT,
      ACCESS_ACTION.DELIVERY_PAGE_SHOW_DRIVER_FULL,
      ACCESS_ACTION.DELIVERY_PAGE_CUSTOMER_ACCEPT,
      ACCESS_ACTION.DELIVERY_PAGE_CUSTOMER_ACCEPT_RETURN,
      ACCESS_ACTION.DELIVERY_PAGE_CUSTOMER_ACCEPT_PICKUP,
      ACCESS_ACTION.DELIVERY_PAGE_VIEW_SCHEDULE
    ]
  },
  customer: {
    static: [ACCESS_ACTION.DASHBOARD_PAGE_VISIT, ACCESS_ACTION.PROFILE_PAGE_VISIT]
  }
};

export default rules;
