import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

function AccessDenied({ isHideMessage }) {
  // Hide message until we load all user data
  if (isHideMessage) {
    return <div />;
  }

  return (
    <div>
      <h1>Access Denied</h1>
      <p>You don&apos;t have permission to access this page</p>
    </div>
  );
}

AccessDenied.propTypes = {
  isHideMessage: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const userAttributes = state.account.get('userAttributes');
  const isHideMessage = userAttributes && Object.keys(userAttributes).length === 0;
  return {
    isHideMessage
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccessDenied);
