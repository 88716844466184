import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Badge, Collapse } from 'reactstrap';

function SidebarCategory({
  name,
  badgeColor,
  badgeText,
  icon: Icon,
  isOpen,
  children,
  onClick,
  location,
  to
}) {
  const getSidebarItemClass = path => {
    return location.pathname.indexOf(path) !== -1 ||
      (location.pathname === '/' && path === '/dashboard')
      ? 'active'
      : '';
  };

  return (
    <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
      <span
        aria-expanded={isOpen ? 'true' : 'false'}
        className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
        data-toggle="collapse"
        onClick={onClick}
      >
        <Icon className="align-middle mr-3" size={18} />
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge className="sidebar-badge" color={badgeColor} size={18}>
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul className="sidebar-dropdown list-unstyled" id="item">
          {children}
        </ul>
      </Collapse>
    </li>
  );
}

SidebarCategory.propTypes = {
  badgeColor: PropTypes.string,
  badgeText: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  name: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string
};
SidebarCategory.defaultProps = {
  badgeColor: '',
  badgeText: '',
  children: '',
  icon: '',
  isOpen: true,
  name: '',
  onClick: () => {},
  to: ''
};

export default withRouter(SidebarCategory);
