import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { hideModal } from '../../redux/ui/ui.actions';
import { onModalClose, onModalOpen } from '../../services/utilities';
import styles from './ModalWindow.module.scss';

class ModalWindow extends Component {
  handleOK = () => {
    this.props.hideModal();
    if (this.props.options.onOk) {
      this.props.options.onOk();
    }
  };

  handleCancel = () => {
    this.props.hideModal();
    if (this.props.options.onCancel) {
      this.props.options.onCancel();
    }
  };

  handleModalOpen = () => {
    // Workaround for case when we should render two modal windows
    if (get(document, 'body.style.position', '') !== 'fixed') {
      ModalWindow.useOnClose = true;
      onModalOpen();
    } else {
      ModalWindow.useOnClose = false;
    }
  };

  handleModalClose = () => {
    if (ModalWindow.useOnClose) {
      ModalWindow.useOnClose = false;
      onModalClose();
    }
  };

  render() {
    const { options } = this.props;

    if (!options) {
      return null;
    }

    return (
      <Modal
        backdropTransition={{
          timeout: 0
        }}
        centered
        isOpen
        modalTransition={{
          timeout: 0
        }}
        onEnter={this.handleModalOpen}
        onExit={this.handleModalClose}
        size="sm"
      >
        {options.title && (
          // eslint-disable-next-line react/jsx-handler-names
          <ModalHeader className={styles.header} toggle={this.handleCancel}>
            {options.title}
          </ModalHeader>
        )}
        <ModalBody className={styles.body}>
          {/* eslint-disable-next-line react/no-danger */}
          <p className="mb-0" dangerouslySetInnerHTML={{ __html: options.body }} />
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <Button color="light" onClick={this.handleCancel} size="lg">
            Cancel
          </Button>{' '}
          <Button color="primary" onClick={this.handleOK} size="lg">
            {options.captionOk || 'Yes'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ModalWindow.useOnClose = false;

ModalWindow.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    captionOk: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func
  }),
  hideModal: PropTypes.func.isRequired
};

ModalWindow.defaultProps = {
  options: null
};

const mapStateToProps = state => ({
  options: state.ui.get('modal')
});

const mapDispatchToProps = {
  hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow);
