/* eslint-disable no-underscore-dangle */

import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr, { reducer as toastrReducer } from 'react-redux-toastr';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import App from './components/App/App';
import accountReducer from './redux/account/account.reducer';
import bridesReducer from './redux/brides/brides.reducer';
import cartReducer from './redux/cart/cart.reducer';
import financialsReducer from './redux/financials/financials.reducer';
import inventoryReducer from './redux/inventory/inventory.reducer';
import suppliersReducer from './redux/suppliers/suppliers.reducer';
import tasksReducer from './redux/tasks/tasks.reducer';
import teamReducer from './redux/team/team.reducer';
import uiReducer from './redux/ui/ui.reducer';
import * as serviceWorker from './serviceWorker';

function configureStore() {
  const createRootReducer = () =>
    combineReducers({
      ui: uiReducer,
      account: accountReducer,
      toastr: toastrReducer,
      team: teamReducer,
      brides: bridesReducer,
      suppliers: suppliersReducer,
      inventory: inventoryReducer,
      cart: cartReducer,
      tasks: tasksReducer,
      financials: financialsReducer
    });

  const middleWare = [thunkMiddleware];

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // Enable Logger for redux
    middleWare.push(createLogger({ collapsed: true }));
    // eslint-disable-next-line global-require
    // const whyDidYouRender = require('@welldone-software/why-did-you-render');
    // whyDidYouRender(React, {
    //   trackAllPureComponents: true
    // });
  }

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middleWare));
  const initialState = undefined;

  const store = createStore(createRootReducer(), initialState, enhancer);

  return {
    store
  };
}

// eslint-disable-next-line import/prefer-default-export
export const { store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
    <ReduxToastr
      closeOnToastrClick={false}
      newestOnTop={false}
      position="bottom-right"
      preventDuplicates
      progressBar
      timeOut={5000}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
