import { Record } from 'immutable';

import { actionTypes as ACCOUNT_ACTIONS } from '../account/account.reducer';

export const actionTypes = {
  TASKS_SET_LIST: 'TASKS_SET_LIST',
  EDIT_TASK: 'EDIT_TASK',
  TASK_GROUPS_SET_LIST: 'TASK_GROUPS_SET_LIST',
  SET_MY_TASKS_LIST: 'SET_MY_TASKS_LIST',
  SET_AVAILABLE_TASKS_LIST: 'SET_AVAILABLE_TASKS_LIST',
  ADD_MY_TASK: 'ADD_MY_TASK',
  REMOVE_MY_TASK: 'REMOVE_MY_TASK',
  SET_CURRENT_TASK: 'SET_CURRENT_TASK',
  UPDATE_TASK_IN_MY_TASKS: 'UPDATE_TASK_IN_MY_TASKS'
};

export const InitialState = Record(
  {
    list: [],
    taskGroups: [],
    myTasks: [],
    availableTasks: {
      list: [],
      LastEvaluatedKey: null
    },
    currentTask: null
  },
  'tasksRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASKS_SET_LIST: {
      return state.merge({
        list: action.tasks
      });
    }
    case actionTypes.EDIT_TASK: {
      const list = state.get('list', []);
      const { index, value } = action;
      return state.merge({
        list: list.map((task, i) => (i === index ? value : task))
      });
    }
    case actionTypes.TASK_GROUPS_SET_LIST: {
      return state.merge({
        taskGroups: action.taskGroups
      });
    }
    case actionTypes.SET_MY_TASKS_LIST: {
      return state.merge({
        myTasks: action.tasks
      });
    }
    case actionTypes.ADD_MY_TASK: {
      const taskToMove = state.availableTasks.list.find(task => task.id === action.task.id);
      return state.merge({
        myTasks: [...state.myTasks, taskToMove],
        availableTasks: {
          list: state.availableTasks.list.filter(task => task.id !== taskToMove.id)
        }
      });
    }
    case actionTypes.UPDATE_TASK_IN_MY_TASKS: {
      return state.merge({
        myTasks: [...state.myTasks.filter(t => t.id !== action.task.id), action.task]
      });
    }
    case actionTypes.REMOVE_MY_TASK: {
      const taskToMove = state.myTasks.find(task => task.id === action.task.id);
      return state.merge({
        myTasks: state.myTasks.filter(task => task.id !== taskToMove.id),
        availableTasks: {
          list: [...state.availableTasks.list, taskToMove]
        }
      });
    }
    case actionTypes.SET_AVAILABLE_TASKS_LIST: {
      return state.merge({
        availableTasks: {
          list: action.tasks,
          LastEvaluatedKey: action.LastEvaluatedKey
        }
      });
    }
    case actionTypes.SET_CURRENT_TASK: {
      return state.merge({
        currentTask: action.task
      });
    }
    case ACCOUNT_ACTIONS.ACCOUNT_DELETE_AUTHENTICATED_USER: {
      return state.merge({
        currentTask: action.task
      });
    }
    default:
      return state;
  }
};
