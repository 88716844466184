import PropTypes from 'prop-types';
import React from 'react';
import { ShoppingCart } from 'react-feather';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';

import ACCESS_ACTION from '../../constants/access-actions';
import ROUTES from '../../constants/routes';
import AccountDecorator from '../../decorators/AccountDecorator';
import { logOut } from '../../redux/account/account.thunks';
import { toggleSidebar } from '../../redux/ui/ui.actions';
import Can from '../CanPerformAction/Can';
import UsernameDropdown from './UsernameDropdown';

function NavbarComponent(props) {
  const handleToggleSidebar = () => props.toggleSidebar();

  const handleLogout = () => props.logOut();

  return (
    <Navbar color="white" expand light>
      <span className="sidebar-toggle d-flex mr-2" onClick={handleToggleSidebar}>
        <i className="hamburger align-self-center" />
      </span>
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <Can
            perform={ACCESS_ACTION.CART_PAGE_VISIT}
            role={props.role}
            yes={() => (
              <UncontrolledDropdown inNavbar nav>
                <span className="d-inline-block">
                  <NavLink className="nav-link" to={ROUTES.CART}>
                    <ShoppingCart className="align-middle" size={18} />
                  </NavLink>
                </span>
              </UncontrolledDropdown>
            )}
          />
          <UsernameDropdown
            displayName={props.authenticatedUser.getDisplayName()}
            onLogout={handleLogout}
          />
        </Nav>
      </Collapse>
    </Navbar>
  );
}

NavbarComponent.propTypes = {
  logOut: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  authenticatedUser: PropTypes.instanceOf(AccountDecorator).isRequired,
  role: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  authenticatedUser: new AccountDecorator(state.account.get('authenticatedUser')),
  role: state.account.get('role')
});

const mapDispatchToProps = {
  logOut,
  toggleSidebar
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
