import PropTypes from 'prop-types';
import React from 'react';

function IconBride(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M311.1,460.8c-0.6,0-1.2-0.1-1.8-0.2c-0.6-0.1-1.2-0.3-1.7-0.5c-0.6-0.2-1.1-0.5-1.6-0.8c-0.5-0.3-1-0.7-1.4-1.1
	c-0.4-0.4-0.8-0.9-1.1-1.4c-0.3-0.5-0.6-1-0.8-1.6c-0.2-0.5-0.4-1.1-0.5-1.7c-0.1-0.6-0.2-1.2-0.2-1.8s0.1-1.2,0.2-1.8
	c0.1-0.6,0.3-1.2,0.5-1.7c0.2-0.5,0.5-1.1,0.8-1.6c0.3-0.5,0.7-1,1.1-1.4c0.4-0.4,0.9-0.8,1.4-1.1c0.5-0.3,1-0.6,1.6-0.8
	c0.5-0.2,1.1-0.4,1.7-0.5c1.2-0.2,2.4-0.2,3.6,0c0.6,0.1,1.2,0.3,1.7,0.5c0.5,0.2,1.1,0.5,1.6,0.8c0.5,0.3,1,0.7,1.4,1.1
	c0.4,0.4,0.8,0.9,1.1,1.4c0.3,0.5,0.6,1,0.8,1.6c0.2,0.6,0.4,1.1,0.5,1.7c0.1,0.6,0.2,1.2,0.2,1.8s-0.1,1.2-0.2,1.8
	c-0.1,0.6-0.3,1.2-0.5,1.7c-0.2,0.6-0.5,1.1-0.8,1.6c-0.3,0.5-0.7,1-1.1,1.4c-0.4,0.4-0.9,0.8-1.4,1.1c-0.5,0.3-1,0.6-1.6,0.8
	c-0.6,0.2-1.1,0.4-1.7,0.5C312.3,460.7,311.7,460.8,311.1,460.8z"
      />
      <path
        d="M449.9,320.5c-0.6-10.5-5.4-55.4-6-59.7c-1-6.9-2.8-17.9-5.5-31c-4.6-22.8-12.5-55.8-24.2-83.6
	c-31.8-75.5-77-98.8-109.5-104.9C292.6,28.7,275.1,21,255.5,21c-19.6,0-37.1,7.7-49.4,20.3c-32.4,6.1-77.7,29.3-109.5,104.9
	C81.3,182.8,70.1,244.8,66,269.8c0,0.3-4.3,39.8-4.9,50.7c-0.9,15,8.5,28.6,22.8,33.1l45.4,14.4c-0.7,3.6-1,7.3-1,11.1V480
	c0,5,4.1,9.1,9.1,9.1h236.3c5,0,9.1-4.1,9.1-9.1V379.1c0-3.8-0.4-7.5-1-11.1l45.4-14.4C441.4,349.1,450.8,335.5,449.9,320.5z
	 M397.5,153.3c17.3,41.2,26.7,97.3,29.3,116c-1.5,1.3-3.2,2.2-5.1,2.8L284,316v-13c43.7-11.3,77.5-48.2,83.5-94.7
	c0.4-1,0.6-2.1,0.6-3.2v-42c0-25.9-8.9-48.4-25.8-65.2c-5.6-5.6-12-10.5-19.2-14.7c-0.4-6.6-1.7-12.8-3.8-18.7
	C351.5,77.3,378.2,107.6,397.5,153.3z M160.8,193.7c0-0.2,0-0.4,0-0.7c23.5-4.6,37.8-26.9,44.7-40.7c23.6,16.3,86.9,56.5,142.6,61.4
	c-9.2,43.2-47.5,74.6-92.6,74.6C203.3,288.4,160.8,245.9,160.8,193.7z M255.2,84.9h0.5c30.7,0,56.9,9.2,73.7,26
	c13.5,13.5,20.4,31.1,20.4,52.3v32.5c-63.2-5.4-141.9-63.9-142.8-64.6c-1.6-1.2-3.5-1.8-5.5-1.8c-0.9,0-1.8,0.1-2.7,0.4
	c-2.8,0.9-5,3-6,5.8c-0.1,0.4-10.9,31.2-31.7,38.6v-9.8C161.2,105.9,211.8,85,255.2,84.9z M255.5,39.3c23.1,0,42,14.5,47.7,34.8
	c-14.3-4.9-30.3-7.5-47.5-7.5c-0.2,0-0.4,0-0.6,0c-17.4,0.1-33.3,2.7-47.4,7.7C213.3,53.9,232.3,39.3,255.5,39.3z M113.5,153.3
	c19.2-45.7,46-76,78.1-88.8c-2.2,6-3.5,12.4-3.8,19c-28.2,16.9-44.9,45.2-44.9,80.7v20.3c-0.2,3-0.4,6.1-0.4,9.1
	c0,52.4,35.9,96.6,84.4,109.3v13L89.4,272.2c-1.8-0.6-3.4-1.4-4.8-2.6C88.3,247.3,99.3,187.3,113.5,153.3z M89.3,336.2
	c-6.3-2-10.4-8-10-14.6c0.5-7.8,1.3-19.1,2.7-32.6c0.6,0.2,1.2,0.4,1.8,0.6l96.2,30.6c-19.3,2.4-35.7,14.2-44.6,30.5L89.3,336.2z
	 M178.4,470.8v-10.1h98.9c5,0,9.1-4.1,9.1-9.1c0-5-4.1-9.1-9.1-9.1h-98.9v-12.8c0-18.7,15.2-34,34-34c12.7,0,34,3.2,34,24.8
	c0,5,4.1,9.1,9.1,9.1c5,0,9.1-4.1,9.1-9.1c0-21.6,21.3-24.8,34-24.8c18.7,0,34,15.2,34,34v41.1H178.4z M364.5,470.8h-13.6v-41.1
	c0-28.8-23.5-52.3-52.3-52.3h0c-19.3,0-34.3,5.9-43.1,16.4c-8.9-10.5-23.8-16.4-43.1-16.4c-28.8,0-52.3,23.5-52.3,52.3v41.1h-13.6
	v-91.7c0-22.6,18.4-41,41-41h48.6c5,0,9.1-4.1,9.1-9.1v-22.7c3.4,0.3,6.8,0.5,10.3,0.5c3.5,0,6.9-0.2,10.3-0.5v22.7
	c0,5,4.1,9.1,9.1,9.1h48.6c22.6,0,41,18.4,41,41V470.8z M421.6,336.2l-46,14.6c-8.9-16.4-25.3-28.1-44.6-30.5l96.2-30.6
	c0.6-0.2,1.2-0.4,1.8-0.6c1.4,13.5,2.3,24.7,2.7,32.6C432.1,328.2,427.9,334.2,421.6,336.2z"
      />
    </svg>
  );
}

IconBride.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconBride.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconBride;
