import { get } from 'lodash';

import { capitalizeFirstLetter } from '../services/utilities';

export default class AccountDecorator {
  constructor(cognitoUser = null) {
    this.cognitoUser = cognitoUser || null;
  }

  getDisplayName() {
    const firstName = get(this.cognitoUser, 'attributes.given_name', '');
    const lastName = get(this.cognitoUser, 'attributes.family_name', '');

    return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
  }
}
