import PropTypes from 'prop-types';
import React from 'react';
import { LogOut, Settings, User } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import ROUTES from '../../constants/routes';

function UsernameDropdown(props) {
  return (
    <UncontrolledDropdown inNavbar nav>
      <span className="d-inline-block d-sm-none">
        <DropdownToggle caret nav>
          <Settings className="align-middle" size={18} />
        </DropdownToggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <DropdownToggle caret nav>
          <span className="text-dark">{props.displayName}</span>
        </DropdownToggle>
      </span>
      <DropdownMenu right>
        <NavLink to={ROUTES.SETTINGS_ACCOUNT}>
          <DropdownItem>
            <User className="align-middle mr-2" size={18} />
            Profile
          </DropdownItem>
        </NavLink>
        <DropdownItem divider />
        <DropdownItem onClick={props.onLogout}>
          <LogOut className="align-middle mr-2" size={18} />
          Sign out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

UsernameDropdown.propTypes = {
  displayName: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired
};

export default UsernameDropdown;
