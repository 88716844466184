import { Record } from 'immutable';

export const actionTypes = {
  PAYMENTS_SET_LIST: 'PAYMENTS_SET_LIST'
};

export const InitialState = Record(
  {
    payments: { list: [], lastEvaluatedKey: {} }
  },
  'financialsRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENTS_SET_LIST: {
      return state.merge({
        payments: {
          list: action.payments,
          lastEvaluatedKey: action.lastEvaluatedKey
        }
      });
    }
    default:
      return state;
  }
};
