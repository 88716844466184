import PropTypes from 'prop-types';
import React from 'react';

function IconAccessories(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 469 469"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1">
        <path
          d="M275,387c-5.2-3-11.2-4.6-17.2-4.6c-12.3,0-23.7,6.6-29.8,17.2c-4.6,8-5.8,17.2-3.4,26.1c2.4,8.9,8.1,16.3,16,20.9
		c5.2,3,11.2,4.6,17.2,4.6c12.3,0,23.7-6.6,29.8-17.2c4.6-8,5.8-17.2,3.4-26.1C288.6,399,282.9,391.5,275,387z M280.7,429.9
		c-4.7,8.2-13.5,13.2-22.9,13.2c-4.6,0-9.2-1.2-13.2-3.5c-6.1-3.5-10.5-9.2-12.3-16.1c-1.8-6.8-0.9-13.9,2.6-20.1
		c4.7-8.2,13.5-13.2,22.9-13.2c4.6,0,9.2,1.2,13.2,3.5c6.1,3.5,10.5,9.2,12.3,16.1C285.2,416.7,284.2,423.8,280.7,429.9z"
        />
        <path
          d="M399.5,382.3c-6,0-11.9,1.6-17.2,4.6c-8,4.6-13.6,12-16,20.9c-2.4,8.9-1.2,18.1,3.4,26.1c6.1,10.6,17.5,17.2,29.8,17.2
		c6,0,11.9-1.6,17.2-4.6c8-4.6,13.6-12,16-20.9c2.4-8.9,1.2-18.1-3.4-26.1C423.1,388.9,411.7,382.3,399.5,382.3z M425,423.6
		c-1.8,6.8-6.2,12.5-12.3,16.1c-4,2.3-8.6,3.5-13.2,3.5c-9.4,0-18.2-5.1-22.9-13.2c-3.5-6.1-4.5-13.2-2.6-20.1
		c1.8-6.8,6.2-12.5,12.3-16.1c4-2.3,8.6-3.5,13.2-3.5c9.4,0,18.2,5.1,22.9,13.2C425.9,409.6,426.9,416.7,425,423.6z"
        />
        <path
          d="M442.7,391.2c-7-12.1-18.8-20.9-32.3-24.2c-12.8-3.1-25.7-1-36.5,5.9c-7.5,4.8-16.8,3.8-22.5-2.1l73.9-105.5
		c5.1-7.2,7-16,5.5-24.7c-1.5-8.7-6.4-16.3-13.6-21.3c-0.9-0.6-1.9-0.8-3-0.7c-1,0.2-2,0.8-2.6,1.6l-83,118.5l-52.4-74.9
		c-1.3-1.8-3.7-2.2-5.5-1c-1.8,1.3-2.2,3.7-1,5.5l58.9,84.1c-3.7,0-7.4,1.1-10.6,3.3c-3.2,2.3-5.5,5.4-6.8,8.8l-72.8-103.9
		c-3.8-5.5-5.3-12.2-4.2-18.8c0.9-5.3,3.5-10,7.3-13.7l19,27.2c1.3,1.8,3.7,2.2,5.5,1c1.8-1.3,2.2-3.7,1-5.5l-21.5-30.7
		c-1.3-1.8-3.7-2.2-5.5-1c-7.2,5.1-12.1,12.6-13.6,21.3c-1.5,8.7,0.4,17.5,5.5,24.7l73.9,105.5c-5.6,5.9-15,6.9-22.5,2.1
		c-10.8-6.9-23.7-9-36.5-5.9c-13.6,3.3-25.3,12.1-32.3,24.2c-6.7,11.6-8.5,25.2-5,38.1c3.5,13,11.8,23.8,23.4,30.5
		c7.7,4.4,16.3,6.7,25.1,6.7c3.6,0,7.3-0.4,10.9-1.2c12.2-2.7,22.9-9.8,30.1-20l29.5-42.2l29.5,42.2c7.2,10.2,17.9,17.3,30.1,20
		c3.6,0.8,7.3,1.2,10.9,1.2c8.7,0,17.4-2.3,25.1-6.7c11.6-6.7,19.9-17.5,23.4-30.5C451.2,416.3,449.4,402.8,442.7,391.2z
		 M415.7,228.2c3.8,3.7,6.3,8.4,7.3,13.7c1.2,6.6-0.3,13.3-4.2,18.8L346.4,364l-2.6-3.7c0,0-10.3-14.7-10.3-14.7L415.7,228.2z
		 M292.6,440.8c-12.7,17.9-36.6,23.1-55.7,12.2c-9.8-5.7-16.8-14.8-19.7-25.7c-2.9-10.9-1.4-22.3,4.2-32.1
		c5.9-10.2,15.9-17.7,27.3-20.5c10.7-2.6,21.4-0.9,30.3,4.9c10.2,6.6,23.1,5.4,31.4-2.2l2.9,4.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1
		l10.3,14.7L292.6,440.8z M440,427.2c-2.9,10.9-9.9,20-19.7,25.7c-19,11-43,5.8-55.6-12.1l-44.7-63.9c-3.3-4.8-2.1-11.3,2.6-14.7
		c4.8-3.3,11.4-2.2,14.7,2.6l5.9,8.5c7.7,11.1,23.1,13.8,34.9,6.2c8.9-5.7,19.7-7.4,30.3-4.9c11.5,2.8,21.4,10.2,27.3,20.5
		C441.5,404.9,443,416.3,440,427.2z"
        />
        <circle cx="328.6" cy="370.9" r="4" />
      </g>
      <g id="Layer_2">
        <path
          d="M268.2,328.2c-4.6-1.5-9.5-2.3-14.6-2.3c-8.7,0-16.5,1.3-23.1,3.9c-6.6,2.5-12,6.2-16,11c-8.1-9.5-21.5-14.9-39-14.9
		c-26.1,0-47.5,21.4-47.5,47.5v37.2h-12.3v-83.2c0-20.5,16.7-37.2,37.2-37.2h44.1c4.5,0,8.3-3.7,8.3-8.3v-20.6
		c3.1,0.3,6.2,0.5,9.3,0.5c2.9,0,11-0.7,11-0.7s-1.1-3.1-1.9-8.2c-0.5-3.4-0.5-8.4-0.5-8.4s-5.7,0.5-8.6,0.5
		c-47.4,0.1-85.9-38.5-85.9-85.8v-0.6c21.3-4.2,34.3-24.4,40.5-36.9c21.4,14.8,78.8,51.2,129.3,55.7c-4.9,23.2-19.1,42.6-38.4,54.6
		l10.3,13.1c24.4-15.9,41.8-41.9,45.7-72.7c0.4-0.9,0.5-1.9,0.5-2.9v-38.1c0-23.5-8.1-43.9-23.4-59.1c-5.1-5.1-10.9-9.5-17.4-13.3
		c-0.4-6-1.5-11.6-3.4-17c29.2,11.6,53.4,39.1,70.9,80.5c15.7,37.4,24.2,88.3,26.6,105.2c-1.4,1.2-2.9,2-4.6,2.5l-87.4,27.9
		c0,0,0,0,0,0l11.3,13.9l81.1-25.8c0.6-0.2,1.1-0.4,1.6-0.5c0.7,7.1,1.3,15.1,1.8,20.6l15-19.8c-1.4-13.5-2.9-24.3-3.2-26.5
		c-0.9-6.3-2.5-16.2-5-28.1c-4.2-20.7-11.3-50.6-21.9-75.8c-28.8-68.5-69.8-89.6-99.3-95.1c-11-11.4-26.8-18.4-44.6-18.4
		c-17.8,0-33.6,7-44.8,18.4c-29.4,5.5-70.5,26.6-99.3,95.1c-13.9,33.2-24,89.4-27.8,112.1c0,0.3-3.9,36.1-4.4,46
		c-0.8,13.6,7.7,25.9,20.7,30l41.2,13.1c-0.6,3.3-0.9,6.6-0.9,10.1v91.5c0,4.5,3.7,8.3,8.3,8.3c32.7,0,65.4,0,98.1,0
		c0-0.2-0.1-0.4-0.1-0.6c-1-5.2-1.2-10.6-0.5-15.9l0-0.1h-60.3v-9.2l61.5-0.1c0.4-1.2,1.8-8,8.6-16.4h-70.2v-11.6
		c0-17,13.8-30.8,30.8-30.8c11.4,0,30.6,2.9,30.8,22.1c0.1,4.4,3.2,8.2,7.5,8.6c4.9,0.4,9-3.4,9-8.2c0-19.6,19.3-22.5,30.8-22.5
		c13.3,0,24.6,8.4,29,20.3c1.2,0.6,2.4,1.4,3.5,2.2c1,0.7,1.9,1.4,2.9,2.1c1.7-3.3,2-7.1,1.8-10.9 M214.5,19.2
		c21,0,38.1,13.1,43.3,31.6c-13-4.4-27.5-6.8-43.1-6.8h-0.5c-15.8,0.1-30.2,2.4-43,7C176.2,32.5,193.4,19.2,214.5,19.2z
		 M105.6,301.7l-41.8-13.1c-5.7-1.8-9.4-7.3-9.1-13.2c0.5-7.1,1.2-17.3,2.5-29.6c0.5,0.2,1.1,0.4,1.6,0.5L146,274
		C128.5,276.2,113.6,286.9,105.6,301.7z M112.4,132.5v18.4c-0.2,2.7-0.4,5.5-0.4,8.3c0,47.5,32.6,87.6,76.6,99.1v11.8L63.8,230.5
		c-1.6-0.5-3.1-1.3-4.4-2.4c3.4-20.2,13.3-74.6,26.2-105.5c17.4-41.5,41.7-68.9,70.8-80.5c-2,5.4-3.2,11.2-3.5,17.2
		C127.5,74.6,112.4,100.3,112.4,132.5z M281.5,84.2c12.2,12.2,18.5,28.2,18.5,47.4v29.5c-57.3-4.9-128.7-58-129.5-58.6
		c-1.5-1.1-3.2-1.6-5-1.6c-0.8,0-1.6,0.1-2.4,0.4c-2.5,0.8-4.5,2.7-5.4,5.3c-0.1,0.4-9.9,28.3-28.7,35v-8.9c0.1-53,46-71.9,85.3-72
		h0.5C242.5,60.6,266.3,68.9,281.5,84.2z"
        />
      </g>
    </svg>
  );
}

IconAccessories.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconAccessories.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconAccessories;
