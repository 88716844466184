import PropTypes from 'prop-types';
import React from 'react';

function IconXLS(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M89.704,99.984l13.824-26.976h-15.32l-3.168,8.824c-1,2.504-2.168,5.504-3.328,9H81.36c-1.496-3.504-2.664-6.504-3.832-9
			l-3.664-8.824H57.88l13.824,26.304l-14.656,27.816h15.328l4-9.656c1.168-2.832,2.328-5.672,3.496-9h0.336
			c1.336,3.328,2.496,6.168,3.832,9l4.336,9.656h15.984L89.704,99.984z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="125.456,115.136 125.456,73.008 111.136,73.008 111.136,127.128 145.936,127.128 145.936,115.136 		" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M180.456,95.992l-6.576-2.576c-4.912-1.92-7.992-2.832-7.992-5.416c0-2.416,2.168-3.664,5.664-3.664
			c4,0,7.16,1.328,10.824,3.912l7.16-8.992c-5-4.904-11.824-7.24-17.984-7.24c-11.824,0-20.152,7.576-20.152,16.824
			c0,7.832,5.08,12.736,10.904,15.064l6.744,2.832c4.576,1.832,7.328,2.672,7.328,5.248c0,2.4-1.832,3.816-6.16,3.816
			c-4,0-8.824-2.168-12.656-5.24l-8.16,9.824c5.832,5.248,13.656,7.752,20.48,7.752c13.488,0,21.144-8.168,21.144-17.32
			C191.024,103.32,187.192,98.656,180.456,95.992z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect height="16" width="16" x="32" y="112" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M413.656,82.344l-80-80C332.16,0.84,330.12,0,328,0h-8H120C97.944,0,80,17.944,80,40H40C17.944,40,0,57.944,0,80v40
			c0,22.056,17.944,40,40,40h40v184c0,22.056,17.944,40,40,40h24c4.416,0,8-3.576,8-8s-3.584-8-8-8h-24c-13.232,0-24-10.768-24-24
			V160h152c22.056,0,40-17.944,40-40V80c0-22.056-17.944-40-40-40H96c0-13.232,10.768-24,24-24h192v48c0,22.056,17.944,40,40,40h48
			v240c0,13.232-10.768,24-24,24h-24c-4.416,0-8,3.576-8,8s3.584,8,8,8h24c22.056,0,40-17.944,40-40V96v-8
			C416,85.872,415.16,83.84,413.656,82.344z M248,56c13.232,0,24,10.768,24,24v40c0,13.232-10.768,24-24,24H40
			c-13.232,0-24-10.768-24-24V80c0-13.232,10.768-24,24-24H248z M352,88c-13.232,0-24-10.768-24-24V19.312L396.688,88H352z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M296,256h-96c-22.056,0-40,17.944-40,40v80c0,22.056,17.944,40,40,40h96c22.056,0,40-17.944,40-40v-80
			C336,273.944,318.056,256,296,256z M320,376c0,13.232-10.768,24-24,24h-96c-13.232,0-24-10.768-24-24v-80
			c0-13.232,10.768-24,24-24h96c13.232,0,24,10.768,24,24V376z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M264,292.688l-48,48V376h35.312l48-48L264,292.688z M244.688,360H232v-12.688l32-32L276.688,328L244.688,360z" />
        </g>
      </g>
    </svg>
  );
}

IconXLS.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconXLS.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconXLS;
