import { Record } from 'immutable';
import moment from 'moment';

export const actionTypes = {
  UI_SHOW_GLOBAL_SPINNER: 'UI_SHOW_GLOBAL_SPINNER',
  UI_HIDE_GLOBAL_SPINNER: 'UI_HIDE_GLOBAL_SPINNER',
  UI_SHOW_MODAL: 'UI_SHOW_MODAL',
  UI_HIDE_MODAL: 'UI_HIDE_MODAL',
  UI_SIDEBAR_VISIBILITY_TOGGLE: 'UI_SIDEBAR_VISIBILITY_TOGGLE',
  UI_SIDEBAR_HIDE: 'UI_SIDEBAR_HIDE',
  UI_SET_DROPDOWN_OPTIONS_FETCHING: 'UI_SET_DROPDOWN_OPTIONS_FETCHING',
  UI_SET_DROPDOWN_OPTIONS: 'UI_SET_DROPDOWN_OPTIONS',
  UI_SET_DROPDOWN_SALES_CONTACTS: 'UI_SET_DROPDOWN_SALES_CONTACTS'
};

export const InitialState = Record(
  {
    globalIsLoading: false,
    globalLoadingStack: [],
    modal: null,
    sidebarIsOpen: true,
    dropdownOptions: {},
    dropdownOptionsFetchingData: false,
    dropdownOptionsLastUpdate: null,
    dropdownSalesContacts: []
  },
  'uiRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_SHOW_GLOBAL_SPINNER: {
      const stack = state.get('globalLoadingStack');
      return state.merge({
        globalLoadingStack: stack.concat([moment().format()]),
        globalIsLoading: true
      });
    }
    case actionTypes.UI_HIDE_GLOBAL_SPINNER: {
      const stack = state.get('globalLoadingStack');
      const isLoading = Array.isArray(stack) ? stack.length > 1 : false;
      return state.merge({
        globalLoadingStack: stack.slice(1),
        globalIsLoading: isLoading
      });
    }
    case actionTypes.UI_SHOW_MODAL:
      return state.set('modal', action.modalOptions);
    case actionTypes.UI_HIDE_MODAL:
      return state.set('modal', null);
    case actionTypes.UI_SIDEBAR_VISIBILITY_TOGGLE: {
      const isOpen = state.get('sidebarIsOpen');
      return state.set('sidebarIsOpen', !isOpen);
    }
    case actionTypes.UI_SIDEBAR_HIDE: {
      return state.set('sidebarIsOpen', true);
    }
    case actionTypes.UI_SET_DROPDOWN_OPTIONS:
      return state.merge({
        dropdownOptionsLastUpdate: moment(),
        dropdownOptions: action.dropdownOptions,
        dropdownOptionsFetchingData: false
      });
    case actionTypes.UI_SET_DROPDOWN_SALES_CONTACTS:
      return state.set('dropdownSalesContacts', action.contacts);
    case actionTypes.UI_SET_DROPDOWN_OPTIONS_FETCHING:
      return state.set('dropdownOptionsFetchingData', action.isFetching === true);
    default:
      return state;
  }
};
