export const SORTING_ORDER = {
  AZ: 'AZ',
  ZA: 'ZA'
};

export const SORTING_NAME = {
  NAME: 'NAME',
  LOGIN: 'LOGIN',
  TYPE: 'TYPE',
  CALL: 'CALL',
  APPT: 'APPT',
  ENGAGEMENT: 'ENGAGEMENT',
  DURATION: 'DURATION',
  WEDDING: 'WEDDING',
  SALES: 'SALES',
  ADDRESS: 'ADDRESS',
  CONTACT: 'CONTACT',
  ACTION: 'ACTION',
  DUE: 'DUE',
  EMAIL: 'EMAIL',
  ROLE: 'ROLE',
  STATUS: 'STATUS',
  COMPANY: 'COMPANY',
  STATE: 'STATE',
  COUNTRY: 'COUNTRY',
  WEB: 'WEB',
  CATEGORIES: 'CATEGORIES',
  NOTES: 'NOTES',
  DESCRIPTION: 'DESCRIPTION',
  COLOR: 'COLOR',
  STYLE: 'STYLE',
  UNIT: 'UNIT',
  UNIT_COST: 'UNIT_COST',
  AMOUNT: 'AMOUNT',
  DATE: 'DATE',
  GOWN: 'GOWN',
  GOWN_NUMBER: 'GOWN_NUMBER',
  GOWN_STYLE: 'GOWN_STYLE',
  GOWN_LOCATION: 'GOWN_LOCATION',
  FABRIC_STYLE: 'FABRIC_STYLE',
  MEASURE_BUST: 'MEASURE_BUST',
  SKIRT_LENGTH: 'SKIRT_LENGTH',
  LACE_STYLE: 'LACE_STYLE',
  LOCATION: 'LOCATION',
  MUSLIN: 'MUSLIN',
  FIRST_FIT: 'FIRST_FIT',
  SECOND_FIT: 'SECOND_FIT',
  THIRD_FIT: 'THIRD_FIT',
  FINAL_FIT: 'FINAL_FIT',
  PICKUP: 'PICKUP',
  TASK: 'TASK',
  DETAILS: 'DETAILS',
  BRIDE: 'BRIDE',
  OWNER: 'OWNER',
  TIME: 'TIME',
  COMPLETED: 'COMPLETED',
  DRY_CLEANING: 'DRY_CLEANING',
  PRODUCTION_OWNER: 'PRODUCTION_OWNER',
  CREATED: 'CREATED',
  TOTAL: 'TOTAL',
  INTEREST: 'INTEREST',
  NET: 'NET',
  TAX: 'TAX',
  REMAINING: 'REMAINING',
  METHOD: 'METHOD',
  EXPORT: 'EXPORT',
  PHONES: 'PHONES',
  PICKUP_AFTER: 'PICKUP_AFTER',
  RETURN_AFTER: 'RETURN_AFTER',
  RETURNED: 'RETURNED'
};

export const RANGE_TYPE = {
  AVAILABILITY: 'availability',
  APPOINTMENT: 'appointment',
  ACCESSORIES: 'accessories',
  ALL: null
};

export const BADGE_COLOR = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const LOCATION = {
  MONSEY: 'Monsey',
  BROOKLYN: 'Brooklyn'
};

export const DEFAULT_LOCATION = LOCATION.BROOKLYN;

export const UPDATE_TIMEOUT_MIN = 5;

export const FORM_MAX_ITEMS = 5;

export const TAX = 8.875;

export const FULL_FILE_EXTENSION = '.jpg';

export const MAX_IMAGE_SIZE_PX = 2000;

export const COMPRESSION_RATIO = 0.7;

export const NOTES_MAX_LENGTH = 150;

export const DROPZONE_ACCEPTED_FILES =
  '.xls, .xlsx, .xlsm, .xlsb, .csv, .ods, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet , application/vnd.ms-excel.sheet.binary.macroEnabled.12 ,application/vnd.ms-excel.sheet.macroEnabled.12, text/csv, application/vnd.oasis.opendocument.spreadsheet';

export const DROPZONE_IMAGES_ACCEPTED_FILES = 'image/*';

export const TELEPHONE_FORMAT_SERVER = 'E.164';
