export const roles = {
  initial: 'initial',
  guest: 'guest',
  owner: 'owner',
  management: 'management',
  presales: 'presales',
  sales: 'sales',
  production: 'production',
  customer: 'customer',
  delivery: 'delivery',
  driver: 'driver'
};

export const userStatus = {
  enabled: 'Enabled',
  disabled: 'Disabled'
};
