export default {
  EMPTY: '',
  SERVER_ERROR_TITLE: 'Server Error',
  UNEXPECTED: 'Unexpected server error occurred.',
  SUCCESSFULLY_ADDED_TO_CART: 'Successfully added to cart',
  SAVE_CHANGES_SUCCESS: 'Changes saved',
  VERIFICATION_CODE_SENT: 'Verification code successfully sent',
  EMAIL_CONFIRMED: 'Email successfully confirmed',
  EXCEL_IMPORT_ERROR_TITLE: 'Excel Import Error',
  EXCEL_IMPORT_FILE_EMPTY: 'File is empty',
  EXCEL_IMPORT_WRONG_FORMAT: 'Wrong file type',
  EXCEL_IMPORT_FILE_SUCCESS: 'File import completed successfully',
  CREATE_TIMESLOTS_SUCCESS: 'New timeslots created successfully',
  UPDATE_TIMESLOTS_SUCCESS: 'Timeslots updated successfully',
  DELETE_APPOINTMENT_SUCCESS: 'Appointment deleted successfully',
  IMAGE_IMPORT_ERROR_TITLE: 'Image reading error',
  IMAGE_READING_ERROR_TITLE: 'An error occurred while retrieving images from the server',
  SAVE_BRIDE_ERROR_TITLE: 'An error occurred while saving bride data',
  SAVE_INVENTORY_ERROR_TITLE: 'An error occurred while saving inventory data',
  TASK_EXISTS_ERROR_TITLE: 'Task with the same name already exists'
};
