import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.container}>
      <Container fluid>
        <Row className="text-muted">
          <Col className="text-right" xs="12">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} -{' '}
              <span className="text-muted">BE&#8231;A&#8231;BRIDE</span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
