const { apiGateway, uritemplate } = window;

/**
 * @param {Object} configuration
 */
export default function(configuration = {}) {
  const apigClient = {};

  const config = { ...configuration };

  if (config.accessKey === undefined) {
    config.accessKey = '';
  }
  if (config.secretKey === undefined) {
    config.secretKey = '';
  }
  if (config.apiKey === undefined) {
    config.apiKey = '';
  }
  if (config.sessionToken === undefined) {
    config.sessionToken = '';
  }
  if (config.region === undefined) {
    config.region = 'us-east-1';
  }

  if (config.defaultContentType === undefined) {
    config.defaultContentType = 'application/json';
  }

  if (config.defaultAcceptType === undefined) {
    config.defaultAcceptType = 'application/json';
  }

  const invokeUrl = config.invokeUrl || '';
  // eslint-disable-next-line no-useless-escape
  const endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
  const pathComponent = invokeUrl.substring(endpoint.length);

  const sigV4ClientConfig = {
    accessKey: config.accessKey,
    secretKey: config.secretKey,
    sessionToken: config.sessionToken,
    serviceName: 'execute-api',
    region: config.region,
    endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType
  };

  let authType = 'NONE';
  if (
    sigV4ClientConfig.accessKey !== undefined &&
    sigV4ClientConfig.accessKey !== '' &&
    sigV4ClientConfig.secretKey !== undefined &&
    sigV4ClientConfig.secretKey !== ''
  ) {
    authType = 'AWS_IAM';
  }

  const simpleHttpClientConfig = {
    endpoint,
    defaultContentType: config.defaultContentType,
    defaultAcceptType: config.defaultAcceptType
  };

  const apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(
    simpleHttpClientConfig,
    sigV4ClientConfig
  );

  apigClient.modelOptions = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, [], ['body']);

    const modelOptionsRequest = {
      verb: 'options'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(
      modelOptionsRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.modelDelete = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, ['model'], ['body']);

    const modelDeleteRequest = {
      verb: 'delete'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(
          apiGateway.core.utils.parseParametersToObject(params, ['model'])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(
      modelDeleteRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.modelGet = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, ['model'], ['body']);

    const modelGetRequest = {
      verb: 'get'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(
          apiGateway.core.utils.parseParametersToObject(params, ['model'])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(modelGetRequest, authType, additionalParams, config.apiKey);
  };

  apigClient.modelPatch = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, ['model'], ['body']);

    const modelPatchRequest = {
      verb: 'patch'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(
          apiGateway.core.utils.parseParametersToObject(params, ['model'])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(
      modelPatchRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.modelPost = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, ['model'], ['body']);

    const modelPostRequest = {
      verb: 'post'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(
          apiGateway.core.utils.parseParametersToObject(params, ['model'])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(
      modelPostRequest,
      authType,
      additionalParams,
      config.apiKey
    );
  };

  apigClient.modelPut = (params, body, additional) => {
    const additionalParams = additional || {};

    apiGateway.core.utils.assertParametersDefined(params, ['model'], ['body']);

    const modelPutRequest = {
      verb: 'put'.toUpperCase(),
      path:
        pathComponent +
        uritemplate('/{model}').expand(
          apiGateway.core.utils.parseParametersToObject(params, ['model'])
        ),
      headers: apiGateway.core.utils.parseParametersToObject(params, []),
      queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
      body
    };

    return apiGatewayClient.makeRequest(modelPutRequest, authType, additionalParams, config.apiKey);
  };

  return apigClient;
}
