const ROUTES = {
  DASHBOARD: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  RESET_PASSWORD: '/password/forgot',
  SIGN_UP_VERIFICATION: login => `/signup/verification/${login}/`,
  RESET_PASSWORD_CONFIRM: login => `/password/new/${login}/`,
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_APPLICATION: '/settings/application',
  TEAM_USERS: '/team/users',
  BRIDES: '/brides',
  BRIDES_PRIORITY: '/brides/priority',
  BRIDES_TASK_TIME: '/brides/task_time',
  BRIDES_PRODUCTION: '/brides/production',
  BRIDES_NEW: '/new/bride',
  BRIDES_DETAILS: brideId => `/bride/${brideId}/`,
  BRIDES_IMPORT: '/brides/import',
  AVAILABILITY: '/availability',
  APPOINTMENTS: '/appointments',
  CALENDAR: '/calendar',
  ACCESSORIES: '/calendar/accessories',
  SUPPLIERS: '/suppliers',
  SUPPLIERS_NEW: '/new/supplier',
  SUPPLIERS_DETAILS: supplierId => `/supplier/${supplierId}/`,
  INVENTORY: '/inventory',
  INVENTORY_NEW: '/new/inventory',
  INVENTORY_DETAILS: inventoryId => `/inventory/${inventoryId}/`,
  CART: '/cart',
  PRODUCTION: '/production',
  PRODUCTION_ALL: '/production/all',
  PRODUCTION_SETTINGS: '/production/settings',
  SAMPLES: '/samples',
  SAMPLES_TASK_TIME: '/samples/task_time',
  SAMPLES_NEW: '/new/sample',
  SAMPLE_DETAILS: sampleId => `/sample/${sampleId}/`,
  FINANCIALS: '/financials',
  FINANCIALS_PAYMENTS: '/financials/payments',
  DELIVERY: '/delivery',
  DELIVERY_DETAILS: deliveryId => `/delivery/${deliveryId}/`
};

export default ROUTES;

export const INITIAL_ROUTE = {
  management: ROUTES.BRIDES,
  presales: ROUTES.BRIDES,
  sales: ROUTES.APPOINTMENTS,
  production: ROUTES.PRODUCTION,
  guest: ROUTES.SETTINGS_ACCOUNT,
  customer: ROUTES.SETTINGS_ACCOUNT,
  delivery: ROUTES.DELIVERY,
  driver: ROUTES.DELIVERY
};

export const SETTINGS_ROUTES = [ROUTES.SETTINGS_ACCOUNT, ROUTES.SETTINGS_APPLICATION];

export function getInitialRoute(role) {
  const route = INITIAL_ROUTE[role];
  return route || ROUTES.DASHBOARD;
}
