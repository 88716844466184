import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import AccessDenied from '../../components/AccessDenied/AccessDenied';
import Can from '../../components/CanPerformAction/Can';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import ROUTES from '../../constants/routes';

function AuthenticatedRoute({
  layout: Layout,
  component: C,
  access,
  props: cProps,
  path,
  ...rest
}) {
  return (
    <Route
      {...rest}
      path={path}
      render={props =>
        cProps.isAuthenticated ? (
          <Can
            no={() => (
              <Layout>
                <AccessDenied />
              </Layout>
            )}
            perform={access}
            role={cProps.role}
            yes={() => (
              <Layout>
                <ErrorBoundary key={path}>
                  <C {...props} {...cProps} />
                </ErrorBoundary>
              </Layout>
            )}
          />
        ) : (
          <Redirect
            // ?redirect=${props.location.pathname}${props.location.search}
            to={`${ROUTES.SIGN_IN}`}
          />
        )
      }
    />
  );
}

AuthenticatedRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

AuthenticatedRoute.defaultProps = {
  location: null
};

export default AuthenticatedRoute;
