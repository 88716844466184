import { Record } from 'immutable';
import moment from 'moment';

import { isObjectEmpty } from '../../services/utilities';

export const actionTypes = {
  INVENTORY_SET_LIST: 'INVENTORY_SET_LIST',
  INVENTORY_UPDATE_ITEM: 'INVENTORY_UPDATE_ITEM',
  INVENTORY_DELETE_ITEM: 'INVENTORY_DELETE_ITEM',
  INVENTORY_ADD_ITEM: 'INVENTORY_ADD_ITEM',
  SUPPLIERS_SET_DROPDOWN_OPTIONS_FETCHING: 'SUPPLIERS_SET_DROPDOWN_OPTIONS_FETCHING',
  SUPPLIERS_SET_DROPDOWN_OPTIONS: 'SUPPLIERS_SET_DROPDOWN_OPTIONS'
};

export const InitialState = Record(
  {
    list: [],
    lastEvaluatedKey: null,
    suppliersDropdownOptions: {},
    suppliersDropdownOptionsFetchingData: false,
    suppliersDropdownOptionsLastUpdate: null,
    lastUpdate: null
  },
  'inventoryRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INVENTORY_SET_LIST: {
      return state.merge({
        list: action.list,
        lastEvaluatedKey: action.lastEvaluatedKey,
        lastUpdate: moment()
      });
    }
    case actionTypes.INVENTORY_UPDATE_ITEM: {
      const list = state.get('list');
      const { data, id } = action;
      const noData = isObjectEmpty(data);
      let updatedList = [];

      if (noData) {
        updatedList = list.filter(el => el.id !== id);
      } else {
        updatedList = list.map(el => {
          if (el.id === id) {
            return { ...data };
          }
          return el;
        });
      }

      return state.set('list', updatedList);
    }
    case actionTypes.INVENTORY_DELETE_ITEM: {
      const { id } = action;
      const updatedList = state.get('list').filter(el => el.id !== id);

      return state.set('list', updatedList);
    }
    case actionTypes.SUPPLIERS_SET_DROPDOWN_OPTIONS_FETCHING:
      return state.set('suppliersDropdownOptionsFetchingData', action.isFetching === true);
    case actionTypes.SUPPLIERS_SET_DROPDOWN_OPTIONS:
      return state.merge({
        suppliersDropdownOptionsLastUpdate: moment(),
        suppliersDropdownOptions: action.dropdownOptions,
        suppliersDropdownOptionsFetchingData: false
      });
    case actionTypes.INVENTORY_ADD_ITEM: {
      const list = state.get('list');
      const { data } = action;
      const inventoryDuplicate = list.find(o => o.id === data.id);
      if (inventoryDuplicate) {
        return state;
      }
      if (list[0] && list[0].category !== data.category) {
        return state;
      }
      return state.set('list', list.concat([data]));
    }
    default:
      return state;
  }
};
