import React from 'react';

import styles from './FullscreenSpinner.module.scss';

function FullscreenSpinner() {
  return (
    <div className={`${styles.container} d-flex justify-content-center align-items-center`}>
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default FullscreenSpinner;
