import PropTypes from 'prop-types';
import React from 'react';

function IconCart(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="48" width="16" x="48" y="376" />
      <rect height="16" width="16" x="80" y="376" />
      <rect height="16" width="16" x="80" y="408" />
      <path d="M196.46,182.055C212.493,188.468,233.638,192,256,192s43.507-3.532,59.54-9.945C333.893,174.714,344,164.041,344,152s-10.107-22.714-28.46-30.055C299.507,115.532,278.362,112,256,112s-43.507,3.532-59.54,9.945C178.107,129.286,168,139.959,168,152S178.107,174.714,196.46,182.055ZM256,128c43.952,0,72,14.214,72,24s-28.048,24-72,24-72-14.214-72-24S212.048,128,256,128Z" />
      <path d="M432,232h-8V152c0-26.556-18.115-51.144-51.008-69.235C341.611,65.505,300.062,56,256,56s-85.611,9.5-116.992,26.765C106.115,100.856,88,125.444,88,152V264c0,26.556,18.115,51.144,51.008,69.235A184.955,184.955,0,0,0,162.485,344H24a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8H432a64.072,64.072,0,0,0,64-64V296A64.072,64.072,0,0,0,432,232ZM112,440H32V360h80ZM408,232H349.515a184.955,184.955,0,0,0,23.477-10.765c14.741-8.107,26.5-17.523,35.008-27.832ZM146.719,96.784C175.777,80.8,214.588,72,256,72s80.223,8.8,109.281,24.784C392.829,111.936,408,131.544,408,152s-15.171,40.064-42.719,55.216C336.223,223.2,297.412,232,256,232s-80.223-8.8-109.281-24.784C119.171,192.064,104,172.456,104,152S119.171,111.936,146.719,96.784Zm0,222.432C119.171,304.064,104,284.455,104,264V193.4c8.5,10.309,20.267,19.725,35.008,27.832q6.234,3.429,12.992,6.442V264h16V234.011a233.64,233.64,0,0,0,24,6.9V264h16V244.1c7.823,1.307,15.841,2.291,24,2.945V296h16V247.894q3.984.1,8,.106h16v16h16V248h24v16h16V248h24v48h16V248h24v16h16V248h24v16h16V250.752A48,48,0,0,1,432,344H256C214.588,344,175.777,335.2,146.719,319.216ZM480,392a48.055,48.055,0,0,1-48,48H128V360h24v16h16V360h24v16h16V360h24v48h16V360h24v16h16V360h24v16h16V360h24v48h16V360h24v16h16V360h24v16h16V357.975a64.071,64.071,0,0,0,32-19.711Z" />
    </svg>
  );
}

IconCart.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconCart.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconCart;
