import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';

function AppliedRoute({ layout: Layout, component: C, props: cProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <ErrorBoundary>
            <C {...props} {...cProps} />
          </ErrorBoundary>
        </Layout>
      )}
    />
  );
}

AppliedRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object.isRequired
};

export default AppliedRoute;
