import PropTypes from 'prop-types';
import React from 'react';

function IconInventory(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="packages">
        <path d="M61,51H57V16a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1V51H3a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V57H27v3a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V57H52v3a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V52A1,1,0,0,0,61,51ZM16,37H9V35h7ZM9,21h7v3a1,1,0,0,0,1.555.832L20,23.2l2.445,1.63A1,1,0,0,0,24,24V21h7V33H9Zm9-4h4v5.131l-1.445-.963a1,1,0,0,0-1.11,0L18,22.131ZM55,37H48V35h7ZM40,37H33V35h7Zm2-2h4v5.131l-1.445-.963a1,1,0,0,0-1.11,0L42,40.131Zm13-2H33V21h7v3a1,1,0,0,0,1.555.832L44,23.2l2.445,1.63A1,1,0,0,0,48,24V21h7ZM46,22.131l-1.445-.963a1,1,0,0,0-1.11,0L42,22.131V17h4ZM40,19H33V17h7ZM18,35h4v5.131l-1.445-.963a1,1,0,0,0-1.11,0L18,40.131Zm6,0h7v2H24Zm9,4h7v3a1,1,0,0,0,1.555.832L44,41.2l2.445,1.63A1,1,0,0,0,48,42V39h7V51H33ZM55,19H48V17h7ZM31,19H24V17h7ZM16,17v2H9V17ZM9,39h7v3a1,1,0,0,0,1.555.832L20,41.2l2.445,1.63A1,1,0,0,0,24,42V39h7V51H9ZM4,53H60v2H4Zm6,6H4V57h6Zm25,0H29V57h6Zm19,0V57h6v2Z" />
      </g>
    </svg>
  );
}

IconInventory.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconInventory.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconInventory;
