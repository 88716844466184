import { Record } from 'immutable';

export const actionTypes = {
  TEAM_SET_USERS: 'TEAM_SET_USERS',
  TEAM_SET_ROLES: 'TEAM_SET_ROLES',
  TEAM_DELETE_USER: 'TEAM_DELETE_USER',
  TEAM_UPDATE_USER: 'TEAM_UPDATE_USER'
};

export const InitialState = Record(
  {
    users: [],
    roles: []
  },
  'teamRecord'
);

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEAM_SET_USERS: {
      return state.merge({
        users: action.users
      });
    }
    case actionTypes.TEAM_SET_ROLES: {
      return state.merge({
        roles: action.roles
      });
    }
    case actionTypes.TEAM_DELETE_USER: {
      const users = state.get('users');
      const { username } = action;

      return state.set(
        'users',
        users.filter(el => el.Username !== username)
      );
    }
    case actionTypes.TEAM_UPDATE_USER: {
      const users = state.get('users');
      const { username, userData } = action;
      const updatedUsers = users.map(el => {
        if (el.Username === username) {
          return Object.assign(el, userData);
        }
        return el;
      });

      return state.set('users', updatedUsers);
    }
    default:
      return state;
  }
};
