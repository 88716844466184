import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ACCESS_ACTION from '../../constants/access-actions';
import ROUTES from '../../constants/routes';
import AccountDecorator from '../../decorators/AccountDecorator';
import { hideSidebar } from '../../redux/ui/ui.actions';
import Can from '../CanPerformAction/Can';
import IconAccessories from '../Icons/IconAccessories';
import IconAppointments from '../Icons/IconAppointments';
import IconAvailability from '../Icons/IconAvailability';
import IconBride from '../Icons/IconBride';
import IconBrideProduction from '../Icons/IconBrideProduction';
import IconCart from '../Icons/IconCart';
import IconFinancials from '../Icons/IconFinancials';
import IconInventory from '../Icons/IconInventory';
import IconPayments from '../Icons/IconPayments';
import IconPickupReturn from '../Icons/IconPickupReturn';
import IconPriority from '../Icons/IconPriority';
import IconProduction from '../Icons/IconProduction';
import IconProductionAll from '../Icons/IconProductionAll';
import IconSample from '../Icons/IconSample';
import IconSettings from '../Icons/IconSettings';
import IconSuppliers from '../Icons/IconSuppliers';
import IconTaskTime from '../Icons/IconTaskTime';
import IconTeam from '../Icons/IconTeam';
import IconXLS from '../Icons/IconXLS';
import Logo from '../Logo/Logo';
import SidebarCategory from './SidebarCategory';
import SidebarItem from './SidebarItem';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nav: [
        {
          name: 'Team',
          icon: IconTeam,
          path: ROUTES.TEAM_USERS,
          access: ACCESS_ACTION.TEAM_USERS_PAGE_VISIT
        },
        {
          name: 'Pickup & Return',
          icon: IconPickupReturn,
          path: ROUTES.DELIVERY,
          access: ACCESS_ACTION.DELIVERY_PAGE_VISIT
        },
        {
          name: 'Brides',
          icon: IconBride,
          path: ROUTES.BRIDES,
          access: ACCESS_ACTION.BRIDES_PAGE_VISIT
        },
        {
          name: 'Brides import',
          icon: IconXLS,
          path: ROUTES.BRIDES_IMPORT,
          access: ACCESS_ACTION.BRIDES_IMPORT_VISIT,
          subItem: true
        },
        {
          name: 'Priority',
          icon: IconPriority,
          path: ROUTES.BRIDES_PRIORITY,
          access: ACCESS_ACTION.BRIDES_PRIORITY_VISIT,
          subItem: true
        },
        {
          name: 'Task Time',
          icon: IconTaskTime,
          path: ROUTES.BRIDES_TASK_TIME,
          access: ACCESS_ACTION.BRIDES_TASK_TIME_VISIT,
          subItem: true
        },
        {
          name: 'Production',
          icon: IconBrideProduction,
          path: ROUTES.BRIDES_PRODUCTION,
          access: ACCESS_ACTION.BRIDES_PRODUCTION_VISIT,
          subItem: true
        },
        {
          name: 'Availability',
          icon: IconAvailability,
          path: ROUTES.AVAILABILITY,
          access: ACCESS_ACTION.AVAILABILITY_PAGE_VISIT
        },
        {
          name: 'Appointments',
          icon: IconAppointments,
          path: ROUTES.APPOINTMENTS,
          access: ACCESS_ACTION.APPOINTMENTS_PAGE_VISIT
        },
        {
          name: 'Calendar',
          icon: IconAvailability,
          path: ROUTES.CALENDAR,
          access: ACCESS_ACTION.CALENDAR_PAGE_VISIT
        },
        {
          name: 'Accessories',
          icon: IconAccessories,
          path: ROUTES.ACCESSORIES,
          access: ACCESS_ACTION.ACCESSORIES_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Samples',
          icon: IconSample,
          path: ROUTES.SAMPLES,
          access: ACCESS_ACTION.SAMPLES_PAGE_VISIT
        },
        {
          name: 'Task Time',
          icon: IconTaskTime,
          path: ROUTES.SAMPLES_TASK_TIME,
          access: ACCESS_ACTION.SAMPLES_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Production',
          icon: IconProduction,
          path: ROUTES.PRODUCTION,
          access: ACCESS_ACTION.PRODUCTION_PAGE_VISIT
        },
        {
          name: 'All Tasks',
          icon: IconProductionAll,
          path: ROUTES.PRODUCTION_ALL,
          access: ACCESS_ACTION.PRODUCTION_ALL_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Settings',
          icon: IconSettings,
          path: ROUTES.PRODUCTION_SETTINGS,
          access: ACCESS_ACTION.PRODUCTION_SETTINGS_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Inventory',
          icon: IconInventory,
          path: ROUTES.INVENTORY,
          access: ACCESS_ACTION.INVENTORY_PAGE_VISIT
        },
        {
          name: 'Cart',
          icon: IconCart,
          path: ROUTES.CART,
          access: ACCESS_ACTION.CART_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Suppliers',
          icon: IconSuppliers,
          path: ROUTES.SUPPLIERS,
          access: ACCESS_ACTION.SUPPLIERS_PAGE_VISIT,
          subItem: true
        },
        {
          name: 'Financials',
          icon: IconFinancials,
          path: ROUTES.FINANCIALS,
          access: ACCESS_ACTION.FINANCIALS_PAGE_VISIT,
          isDisabled: true
        },
        {
          name: 'Payments',
          icon: IconPayments,
          path: ROUTES.FINANCIALS_PAYMENTS,
          access: ACCESS_ACTION.FINANCIALS_PAGE_VISIT,
          subItem: true
        }
      ]
    };
  }

  handleHideSidebar = () => this.props.hideSidebar();

  render() {
    const { nav } = this.state;
    const { role } = this.props;

    return (
      <nav className={`sidebar ${!this.props.isSidebarOpen ? ' toggled' : ''}`}>
        <div className="sidebar-content">
          <PerfectScrollbar>
            <NavLink onClick={this.handleHideSidebar} to={ROUTES.DASHBOARD}>
              <Logo height={7} margin={1} width={15.8} />
            </NavLink>

            <ul className="sidebar-nav">
              {nav.map(category => {
                return (
                  <Can
                    key={category.path}
                    perform={category.access}
                    role={role}
                    yes={() => (
                      <React.Fragment key={category.name}>
                        {category.header ? (
                          <li className="sidebar-header">{category.header}</li>
                        ) : null}

                        {category.children ? (
                          <SidebarCategory
                            badgeColor={category.badgeColor}
                            badgeText={category.badgeText}
                            icon={category.icon}
                            isDisabled={category.isDisabled}
                            isOpen={category.isOpen}
                            name={category.name}
                            to={category.path}
                          >
                            {category.children.map(route => (
                              <SidebarItem
                                key={route.path}
                                badgeColor={route.badgeColor}
                                badgeText={route.badgeText}
                                name={route.name}
                                to={route.path}
                              />
                            ))}
                          </SidebarCategory>
                        ) : (
                          <SidebarItem
                            badgeColor={category.badgeColor}
                            badgeText={category.badgeText}
                            icon={category.icon}
                            isDisabled={category.isDisabled}
                            name={category.name}
                            subItem={category.subItem}
                            to={category.path}
                          />
                        )}
                      </React.Fragment>
                    )}
                  />
                );
              })}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  hideSidebar: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  authenticatedUser: new AccountDecorator(state.account.get('authenticatedUser')),
  isSidebarOpen: state.ui.get('sidebarIsOpen'),
  role: state.account.get('role')
});

const mapDispatchToProps = {
  hideSidebar
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
