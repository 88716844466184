import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { getInitialRoute } from '../../constants/routes';
import { roles } from '../../constants/user-roles';

// PARSE URL PARAMS
// function querystring(stringName, url = window.location.href) {
//   const name = stringName.replace(/[[]]/g, '\\$&');
//   const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
//   const results = regex.exec(url);
//   if (!results) {
//     return null;
//   }
//   if (!results[2]) {
//     return '';
//   }
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }

function UnauthenticatedRoute({ layout: Layout, component: C, props: cProps, path, ...rest }) {
  return (
    <Route
      {...rest}
      path={path}
      render={props =>
        !cProps.isAuthenticated || cProps.role === roles.initial ? (
          <Layout>
            <ErrorBoundary key={path}>
              <C {...props} {...cProps} />
            </ErrorBoundary>
          </Layout>
        ) : (
          <Redirect to={getInitialRoute(cProps.role)} />
        )
      }
    />
  );
}

UnauthenticatedRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  path: PropTypes.string.isRequired
};

UnauthenticatedRoute.defaultProps = {
  location: null
};

export default UnauthenticatedRoute;
