import PropTypes from 'prop-types';
import React from 'react';

import styles from './Main.module.scss';

function Main({ className, children }) {
  return <div className={`${styles.main} ${className}`}>{children}</div>;
}

Main.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
};

Main.defaultProps = {
  className: ''
};

export default Main;
