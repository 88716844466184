import { actionTypes } from './ui.reducer';

export function showGlobalSpinner() {
  return {
    type: actionTypes.UI_SHOW_GLOBAL_SPINNER
  };
}

export function hideGlobalSpinner() {
  return {
    type: actionTypes.UI_HIDE_GLOBAL_SPINNER
  };
}

export function showModal(modalOptions = {}) {
  return {
    type: actionTypes.UI_SHOW_MODAL,
    modalOptions: { ...modalOptions }
  };
}

export function hideModal() {
  return {
    type: actionTypes.UI_HIDE_MODAL
  };
}

export function toggleSidebar() {
  return {
    type: actionTypes.UI_SIDEBAR_VISIBILITY_TOGGLE
  };
}

export function hideSidebar() {
  return {
    type: actionTypes.UI_SIDEBAR_HIDE
  };
}

export function setDropdownOptions(options = {}) {
  return {
    type: actionTypes.UI_SET_DROPDOWN_OPTIONS,
    dropdownOptions: { ...options }
  };
}

export function setDropdownSalesContacts(contacts = []) {
  return {
    type: actionTypes.UI_SET_DROPDOWN_SALES_CONTACTS,
    contacts: contacts || []
  };
}

export function setDropdownOptionsIsFetchingData(isFetching) {
  return {
    type: actionTypes.UI_SET_DROPDOWN_OPTIONS_FETCHING,
    isFetching
  };
}
