import PropTypes from 'prop-types';
import React from 'react';

function IconAccessories(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 469.141 469.141"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M133.118,318.689c-9.878-5.703-21.097-8.718-32.445-8.718c-23.176,0-44.765,12.456-56.342,32.508
		c-8.681,15.035-10.987,32.551-6.494,49.32c4.494,16.771,15.249,30.786,30.284,39.467c9.877,5.702,21.096,8.717,32.444,8.718
		c23.178,0,44.766-12.456,56.343-32.508c8.681-15.035,10.986-32.551,6.493-49.32C158.908,341.386,148.153,327.37,133.118,318.689z
		 M143.918,399.976c-8.907,15.426-25.518,25.009-43.352,25.008c-8.719-0.001-17.345-2.32-24.945-6.708
		c-11.565-6.678-19.838-17.459-23.294-30.358c-3.457-12.899-1.683-26.373,4.995-37.938c8.906-15.425,25.517-25.008,43.352-25.008
		c8.718,0,17.344,2.319,24.945,6.708c11.565,6.677,19.838,17.459,23.295,30.358C152.369,374.937,150.595,388.411,143.918,399.976z"
      />
      <path
        d="M368.469,309.971c-11.348,0-22.567,3.015-32.445,8.718c-15.035,8.681-25.79,22.696-30.284,39.467
		c-4.493,16.77-2.188,34.285,6.493,49.32c11.577,20.052,33.166,32.508,56.342,32.508c11.348,0,22.567-3.015,32.445-8.718
		c15.035-8.681,25.79-22.696,30.284-39.467c4.493-16.77,2.187-34.285-6.494-49.32C413.234,322.428,391.645,309.971,368.469,309.971z
		 M416.815,387.918c-3.456,12.899-11.729,23.681-23.294,30.358c-7.601,4.388-16.227,6.708-24.945,6.708
		c-17.834,0-34.446-9.583-43.352-25.008c-6.677-11.565-8.451-25.039-4.995-37.938c3.457-12.899,11.729-23.682,23.295-30.358
		c7.601-4.389,16.227-6.708,24.945-6.708c17.834,0,34.446,9.583,43.352,25.008C418.497,361.545,420.271,375.018,416.815,387.918z"
      />
      <path
        d="M450.19,326.625c-13.212-22.886-35.493-39.582-61.126-45.81c-24.177-5.873-48.687-1.922-69.017,11.126
		c-14.091,9.042-31.856,7.11-42.525-3.972L417.27,88.402c9.576-13.675,13.252-30.261,10.354-46.701
		c-2.899-16.441-12.027-30.77-25.702-40.345c-1.629-1.141-3.646-1.588-5.604-1.242c-1.959,0.345-3.701,1.454-4.841,3.084
		L234.57,227.283L135.463,85.742c-2.375-3.393-7.052-4.218-10.445-1.842c-3.393,2.376-4.218,7.053-1.842,10.445l111.317,158.979
		c-6.923,0.005-13.909,2.046-19.998,6.309c-6.086,4.262-10.392,10.126-12.765,16.627L64.159,79.799
		c-7.277-10.394-10.072-22.998-7.869-35.493c1.763-9.997,6.556-18.966,13.726-25.88l35.953,51.346
		c2.376,3.394,7.054,4.219,10.445,1.842c3.393-2.376,4.218-7.053,1.842-10.445L77.665,3.199c-2.376-3.394-7.052-4.217-10.445-1.842
		c-13.675,9.575-22.803,23.903-25.702,40.344s0.778,33.026,10.354,46.701L191.619,287.97c-10.668,11.083-28.433,13.014-42.524,3.972
		c-20.33-13.049-44.84-16.999-69.015-11.126c-25.635,6.227-47.915,22.924-61.128,45.81c-12.688,21.977-16.059,47.579-9.491,72.09
		c6.568,24.512,22.288,44.999,44.265,57.687c14.571,8.413,30.902,12.74,47.427,12.739c6.877,0,13.79-0.749,20.621-2.266
		c23.095-5.127,43.313-18.547,56.954-37.818l55.844-79.749l55.867,79.781c13.618,19.24,33.837,32.66,56.932,37.787
		c6.833,1.517,13.742,2.267,20.622,2.266c16.523,0,32.856-4.327,47.427-12.739c21.977-12.688,37.697-33.175,44.265-57.687
		S462.879,348.601,450.19,326.625z M399.126,18.425c7.169,6.914,11.962,15.883,13.726,25.881c2.203,12.495-0.591,25.1-7.869,35.493
		L268.137,275.221l-4.892-6.987c-0.013-0.018-19.519-27.876-19.519-27.876L399.126,18.425z M166.461,420.422
		c-23.991,33.894-69.232,43.775-105.237,22.989c-18.507-10.685-31.745-27.937-37.276-48.578s-2.692-42.201,7.993-60.708
		c11.173-19.352,30.009-33.47,51.678-38.733c20.153-4.898,40.528-1.638,57.373,9.173c19.341,12.413,43.643,10.214,59.335-4.157
		l5.401,7.714c0.059,0.085,0.111,0.173,0.171,0.258c0.025,0.035,0.054,0.064,0.079,0.1l19.435,27.754L166.461,420.422z
		 M445.193,394.834c-5.531,20.642-18.769,37.894-37.276,48.578c-36.005,20.788-81.247,10.904-105.215-22.958l-84.596-120.81
		c-6.215-9.022-3.997-21.429,4.993-27.725c9.03-6.323,21.523-4.123,27.851,4.906l11.203,16
		c14.654,20.93,43.645,26.087,65.996,11.738c16.846-10.811,37.222-14.068,57.374-9.173c21.669,5.264,40.504,19.382,51.677,38.733
		C447.885,352.632,450.724,374.192,445.193,394.834z"
      />
      <circle cx="234.571" cy="288.304" r="7.5" />
    </svg>
  );
}

IconAccessories.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconAccessories.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconAccessories;
