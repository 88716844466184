import Auth from '@aws-amplify/auth';

/**
 * @param {string} username
 * @param {string} password
 * @return {Promise<any>}
 */
export function signIn(username, password) {
  const login = username.toLowerCase();
  return Auth.signIn(login, password);
}

/**
 * @param {string} username
 * @param {string} password
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string} cell
 * @return {Promise<>}
 */
export function signUp(username, password, firstName, lastName, email, cell) {
  const login = username.toLowerCase();
  const givenName = firstName.trim();
  const familyName = lastName.trim();
  const userEmail = email.trim().toLocaleLowerCase();
  const phoneNumber = cell.trim();

  return Auth.signUp({
    username: login,
    password,
    attributes: {
      given_name: givenName,
      family_name: familyName,
      email: userEmail,
      phone_number: phoneNumber
    }
  });
}

/**
 * @return {Promise<any>}
 */
export function signOut() {
  return Auth.signOut();
}

/**
 * @param {string} username
 * @param {string} code
 * @return {Promise<any>}
 */
export function confirmSignUp(username, code) {
  const login = username.toLowerCase();

  return Auth.confirmSignUp(login, code);
}

/**
 * @param {string} username
 * @return {Promise<string>}
 */
export function resendSignUp(username) {
  const login = username.toLowerCase();

  return Auth.resendSignUp(login);
}

/**
 * @param {string} username
 * @return {Promise<any>}
 */
export function forgotPassword(username) {
  const login = username.toLowerCase();

  return Auth.forgotPassword(login);
}

export function resendVerificationCode(attributeName) {
  return Auth.verifyCurrentUserAttribute(attributeName);
}

export function confirmVerificationCode(attributeName, code) {
  return Auth.verifyCurrentUserAttributeSubmit(attributeName, code);
}

/**
 * @param {string} username
 * @param {string} code
 * @param {string} password
 * @return {Promise<void>}
 */
export function forgotPasswordSubmit(username, code, password) {
  const login = username.toLowerCase();

  return Auth.forgotPasswordSubmit(login, code, password);
}

/**
 * @return {Promise<object>}
 */
export function getUserAttributes() {
  return Auth.currentUserInfo().then(data => (data && data.attributes ? data.attributes : {}));
}

/**
 * @param {object} attributes
 * @return {Promise<string>}
 */
export function updateUserAttributes(attributes) {
  return Auth.currentAuthenticatedUser().then(cognitoUser =>
    Auth.updateUserAttributes(cognitoUser, attributes)
  );
}

/**
 * @return {Promise<any>}
 */
export function getCurrentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

/**
 * @return {Promise<ICredentials>}
 */
export function getCurrentUserCredentials() {
  return Auth.currentUserCredentials();
}

/**
 * @return {Promise}
 */
export function getUserSession() {
  return Auth.currentSession();
}

/**
 * @return {Promise<string>}
 */
export function getIdentityJWT() {
  const NO_TOKEN = '';

  return getUserSession()
    .then(session => {
      if (session && session.idToken && session.idToken.jwtToken) {
        return session.idToken.jwtToken;
      }

      return NO_TOKEN;
    })
    .catch(() => NO_TOKEN);
}
