import PropTypes from 'prop-types';
import React from 'react';

function IconAppointments(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m17 29h2v2h-2z" />
      <path d="m25 37h2v2h-2z" />
      <path d="m9 45h2v2h-2z" />
      <path d="m56 57c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1z" />
      <path d="m40 57c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1z" />
      <path d="m48 57c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1z" />
      <path d="m41 5h18v2h-18z" />
      <path d="m45 9h14v2h-14z" />
      <path d="m41 9h2v2h-2z" />
      <path d="m57 13h2v2h-2z" />
      <path d="m41 13h14v2h-14z" />
      <path d="m58 19c2.757 0 5-2.243 5-5v-8c0-2.757-2.243-5-5-5h-16c-2.757 0-5 2.243-5 5v7h-8v-2c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v2h-8v-2c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v2h-3c-2.757 0-5 2.243-5 5v32c0 2.757 2.243 5 5 5h27.059c.499 4.493 4.316 8 8.941 8h12c4.963 0 9-4.038 9-9s-4.037-9-9-9h-3v-26zm-19-13c0-1.654 1.346-3 3-3h16c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3h-9.266l-5.734 3.277v-3.277h-1c-1.654 0-3-1.346-3-3zm-14 5h2l.001 6h-2.001zm-14 0h2l.001 6h-2.001zm28 34v-2h6v2zm-16-10h6v6h-6zm-2 6h-6v-6h6zm10-6h6v6h-6zm8 0h6v6h-6zm6-2h-6v-6h6zm-8 0h-6v-6h6zm-8 0h-6v-6h6zm-8 0h-6v-6h6zm-8 0h-6v-6h6zm-6 2h6v6h-6zm0 8h6v6h-6zm8 0h6v6h-6zm8 0h6v6h-6zm8 0h6v2h-4v4h-2zm30 11c0 3.86-3.141 7-7 7h-12c-3.859 0-7-3.14-7-7v-7h19c3.859 0 7 3.14 7 7zm-14-9v-18c0-1.103-.897-2-2-2h-38c-1.103 0-2 .897-2 2v22c0 1.103.897 2 2 2h26v2h-27c-1.654 0-3-1.346-3-3v-27h36v-2h-36v-3c0-1.654 1.346-3 3-3h3v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2h8v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2h8.11c.4 1.955 1.935 3.501 3.89 3.899v4.824l8-4.571v25.848z" />
      <path d="m41 29h2v2h-2z" />
    </svg>
  );
}

IconAppointments.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconAppointments.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconAppointments;
