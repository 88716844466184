import PropTypes from 'prop-types';
import React from 'react';

function IconAccessories(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="5 10 80 80"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M12.126,51.492l0.753,0.305l-0.087-0.98c0-1.238,1.01-2.246,2.25-2.246c0.315,0,0.627,0.068,0.928,0.205   l0.608,0.277l0.094-0.662c0.222-1.559,1.572-2.736,3.141-2.736c0.715,0,1.399,0.246,1.979,0.709l0.771,0.617l0.042-0.986   c0.098-2.32,2.065-4.139,4.479-4.139c1.429,0,2.788,0.67,3.637,1.795l0.713,0.943l0.18-1.17c0.375-2.43,2.237-4.193,4.429-4.193v-1   c-2.383,0-4.444,1.667-5.195,4.08c-1.013-0.924-2.36-1.455-3.763-1.455c-2.658,0-4.874,1.816-5.377,4.264   c-0.587-0.305-1.229-0.465-1.894-0.465c-1.861,0-3.489,1.26-4.004,3.01c-0.251-0.063-0.508-0.094-0.767-0.094   c-1.648,0-3.015,1.232-3.223,2.824c-1.451-0.158-2.757,1.021-2.757,2.484c0,1.377,1.122,2.498,2.5,2.498v-1   c-0.827,0-1.5-0.672-1.5-1.498C10.063,51.861,11.148,51.096,12.126,51.492z" />
        <path d="M78.389,50.395c-0.207-1.592-1.574-2.824-3.223-2.824c-0.258,0-0.514,0.031-0.766,0.094   c-0.516-1.75-2.143-3.01-4.004-3.01c-0.665,0-1.307,0.16-1.895,0.465c-0.502-2.447-2.719-4.264-5.377-4.264   c-1.4,0-2.75,0.531-3.764,1.455c-0.749-2.413-2.811-4.08-5.193-4.08v1c1.488,0,2.811,0.824,3.633,2.107h-4.191v1h4.691   c0.128,0.346,0.235,0.703,0.295,1.086l0.021,0.143h-2.234v1h2.371v-0.105l0.021,0.133l0.713-0.943   c0.861-1.141,2.188-1.795,3.637-1.795c1.529,0,2.876,0.732,3.686,1.857h-4.243v1h4.759c0.146,0.389,0.248,0.795,0.271,1.229H65.34   v1h2.306l0.001,0.039l0.049-0.039h0.018v-0.014l0.705-0.564c0.578-0.463,1.264-0.709,1.979-0.709c1.445,0,2.693,1.004,3.057,2.381   h-2.201v1h2.371v-0.033l0.008,0.051l0.607-0.277c0.301-0.137,0.613-0.205,0.928-0.205c1.211,0,2.195,0.961,2.236,2.215h-2.338v1   h2.273l-0.002,0.01l0.025-0.01h0.074v-0.031l0.648-0.262c0.947-0.385,1.988,0.328,2.051,1.299h-3.801v1h3.494   c-0.275,0.354-0.699,0.586-1.182,0.586v1c1.379,0,2.5-1.121,2.5-2.498C81.146,51.416,79.84,50.242,78.389,50.395z" />
        <path d="M82.084,54.982c-0.896,0-1.625,0.729-1.625,1.623s0.729,1.623,1.625,1.623s1.625-0.729,1.625-1.623   S82.98,54.982,82.084,54.982z M82.084,57.229c-0.346,0-0.625-0.279-0.625-0.623s0.279-0.623,0.625-0.623   c0.344,0,0.625,0.279,0.625,0.623S82.428,57.229,82.084,57.229z" />
        <path d="M7.916,54.982c-0.896,0-1.625,0.729-1.625,1.623s0.729,1.623,1.625,1.623c0.896,0,1.625-0.729,1.625-1.623   S8.813,54.982,7.916,54.982z M7.916,57.229c-0.345,0-0.625-0.279-0.625-0.623s0.28-0.623,0.625-0.623s0.625,0.279,0.625,0.623   S8.261,57.229,7.916,57.229z" />
        <path d="M45.106,45.262l4.45-7.708c0.09-0.145,0.873-1.443,0.873-2.428c0-1.85-1.512-3.354-3.367-3.354   c-0.705,0-1.387,0.222-1.959,0.633c-0.57-0.411-1.251-0.633-1.957-0.633c-1.856,0-3.367,1.505-3.367,3.354   c0,0.985,0.788,2.284,0.87,2.416L45.106,45.262z M43.146,32.771c0.598,0,1.172,0.229,1.615,0.644l0.342,0.319l0.342-0.318   c0.444-0.416,1.02-0.645,1.617-0.645c1.086,0,1.996,0.737,2.271,1.732h-0.701v1h0.741c-0.073,0.302-0.208,0.637-0.347,0.928h-3.882   v1h3.327l-3.366,5.83l-3.6-6.233c-0.202-0.327-0.728-1.307-0.728-1.902C40.779,33.828,41.842,32.771,43.146,32.771z" />
        <path d="M12.013,53.771l0.389,0.922c7.37-3.109,19.159-4.965,31.536-4.965c12.5,0,24.357,1.885,31.721,5.043   l0.395-0.92c-7.482-3.207-19.486-5.123-32.115-5.123C31.248,48.729,19.611,50.566,12.013,53.771z" />
        <circle cx="73.854" cy="51.291" r="0.734" />
        <circle cx="64.131" cy="46.447" r="0.734" />
        <circle cx="55.172" cy="44.072" r="0.734" />
        <circle cx="47.422" cy="35.011" r="0.734" />
        <circle cx="70.041" cy="48.541" r="0.734" />
      </g>
    </svg>
  );
}

IconAccessories.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconAccessories.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconAccessories;
