import PropTypes from 'prop-types';
import React from 'react';

import accessRules from '../../constants/access-rules';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

class Can extends React.PureComponent {
  render() {
    return check(accessRules, this.props.role, this.props.perform, this.props.data)
      ? this.props.yes()
      : this.props.no();
  }
}

Can.propTypes = {
  role: PropTypes.string.isRequired,
  perform: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  yes: PropTypes.func,
  no: PropTypes.func
};

Can.defaultProps = {
  data: {},
  yes() {
    return null;
  },
  no() {
    return null;
  }
};

export default Can;

export function canDoBool(role, perform, data = {}) {
  if (!perform) throw new Error('Invalid Access Rule');
  return check(accessRules, role, perform, data);
}
