export const RETRY_OPTIONS = {
  retries: 4,
  minTimeout: 100,
  maxTimeout: 500
};

export const API_METHOD = {
  POST: 'modelPost',
  GET: 'modelGet',
  DELETE: 'modelDelete',
  PATCH: 'modelPatch',
  PUT: 'modelPut'
};

export const API_MODEL = {
  DEFAULT: 'default',
  ROLE: 'role',
  INIT: 'init',
  BRIDES: 'brides',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  CALENDAR: 'calendar',
  SUPPLIERS: 'suppliers',
  INVENTORY: 'inventory',
  CART: 'cart',
  TASK: 'task',
  TASK_TIME: 'tasktime',
  SAMPLE: 'sample',
  PAYMENT: 'payment',
  DELIVERY: 'delivery'
};
