const dev = {
  region: 'us-east-1',
  s3region: 'us-east-1',
  s3bucket: 'album.beabride.larrosatek.com',
  IdentityPoolId: 'us-east-1:46d141b0-29bd-4882-8354-23d9191314f1',
  UserPoolId: 'us-east-1_9k0wFRomf',
  ClientId: '14mdb1lk96c1fico0bl0fb8pd6',
  invokeUrl: 'https://tey1e5czvi.execute-api.us-east-1.amazonaws.com/prod'
};

const prod = {
  region: 'us-east-1',
  s3region: 'us-east-1',
  s3bucket: 'album.beabridebp.org',
  IdentityPoolId: 'us-east-1:6940b128-8245-40be-9117-2e4a24e3c13a',
  UserPoolId: 'us-east-1_7QYI5Go7g',
  ClientId: '3vo7cbdo1eneor1o0110r1skq3',
  invokeUrl: 'https://api.beabridebp.org'
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config;
