import PropTypes from 'prop-types';
import React from 'react';

function IconAvailability(props) {
  const { color, size, className } = props;
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      stroke="none"
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="XMLID_1031_">
        <g id="XMLID_971_">
          <g id="XMLID_977_">
            <path
              d="M457.671,98.001h15.83c5.522,0,10-4.478,10-10s-4.478-10-10-10h-38c-5.522,0-10,4.478-10,10v38     c0,5.522,4.478,10,10,10s10-4.478,10-10v-11.003c30.342,40.621,46.835,89.908,46.835,141.17     c0,130.224-105.944,236.168-236.168,236.168S20,386.392,20,256.168S125.944,20,256.168,20c9.631,0,19.324,0.585,28.812,1.738     c5.475,0.662,10.468-3.237,11.134-8.72c0.667-5.482-3.237-10.468-8.72-11.134C277.108,0.634,266.603,0,256.168,0     C187.743,0,123.413,26.646,75.03,75.03C26.646,123.413,0,187.743,0,256.168s26.646,132.755,75.03,181.138     c48.383,48.384,112.713,75.03,181.138,75.03s132.755-26.646,181.138-75.03c48.384-48.383,75.03-112.713,75.03-181.138     C512.336,198.427,493.046,142.995,457.671,98.001z"
              id="XMLID_1030_"
            />
            <path
              d="M108.472,151.013c-4.667-2.954-10.844-1.564-13.799,3.1c-22.829,36.056-32.935,79.418-28.456,122.099     c4.545,43.314,24.04,84.16,54.894,115.015c37.235,37.235,86.146,55.853,135.058,55.853s97.822-18.617,135.058-55.853     c74.471-74.471,74.471-195.645,0-270.115c-30.807-30.806-71.586-50.294-114.828-54.874     c-42.602-4.515-85.917,5.512-121.958,28.229c-4.673,2.945-6.072,9.12-3.128,13.792c2.945,4.673,9.12,6.072,13.792,3.128     c67.938-42.82,155.105-33.007,211.979,23.867c66.673,66.673,66.673,175.157,0,241.83c-66.672,66.674-175.158,66.674-241.83,0     c-56.088-56.088-66.048-145.361-23.682-212.271C114.525,160.146,113.138,153.968,108.472,151.013z"
              id="XMLID_1029_"
            />
            <path
              d="M386.169,266.33h12.332c5.522,0,10-4.478,10-10s-4.478-10-10-10h-12.332c-5.522,0-10,4.478-10,10     S380.646,266.33,386.169,266.33z"
              id="XMLID_1028_"
            />
            <path
              d="M103.835,256.006c0,5.522,4.478,10,10,10h12.332c5.522,0,10-4.478,10-10s-4.478-10-10-10h-12.332     C108.313,246.006,103.835,250.483,103.835,256.006z"
              id="XMLID_1004_"
            />
            <path
              d="M266.33,126.167v-12.332c0-5.522-4.478-10-10-10s-10,4.478-10,10v12.332c0,5.522,4.478,10,10,10     S266.33,131.689,266.33,126.167z"
              id="XMLID_1001_"
            />
            <path
              d="M246.006,386.169v12.332c0,5.522,4.478,10,10,10s10-4.478,10-10v-12.332c0-5.522-4.478-10-10-10     S246.006,380.646,246.006,386.169z"
              id="XMLID_1415_"
            />
            <path
              d="M206.044,191.901c-3.906-3.904-10.236-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l33.891,33.891     c-2.597,4.84-4.074,10.367-4.074,16.233c0,18.996,15.454,34.45,34.45,34.45s34.45-15.454,34.45-34.45     c0-5.866-1.478-11.393-4.074-16.233l68.383-68.383c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.904-10.236-3.904-14.143,0     l-68.383,68.383c-4.84-2.597-10.367-4.074-16.233-4.074s-11.393,1.478-16.233,4.074L206.044,191.901z M270.618,256.168     c0,7.968-6.482,14.45-14.45,14.45s-14.45-6.482-14.45-14.45s6.482-14.45,14.45-14.45S270.618,248.2,270.618,256.168z"
              id="XMLID_996_"
            />
            <path
              d="M135.248,135.248c1.87-1.86,2.939-4.44,2.939-7.07s-1.069-5.2-2.939-7.07     c-1.86-1.859-4.431-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93c-1.859,1.86-2.93,4.44-2.93,7.07c0,2.64,1.07,5.21,2.93,7.07     c1.86,1.859,4.44,2.93,7.07,2.93C130.817,138.178,133.388,137.107,135.248,135.248z"
              id="XMLID_980_"
            />
            <path
              d="M325.438,29.878c2.641,0,5.221-1.061,7.08-2.93c1.86-1.86,2.931-4.431,2.931-7.07     c0-2.63-1.07-5.21-2.931-7.07c-1.859-1.859-4.439-2.93-7.08-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07     c0,2.64,1.07,5.21,2.931,7.07C320.228,28.817,322.808,29.878,325.438,29.878z"
              id="XMLID_979_"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

IconAvailability.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

IconAvailability.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

export default IconAvailability;
