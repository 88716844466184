import '@aws-amplify/auth';
import 'react-dates/initialize';
import './theme/modern.scss';

import Amplify from '@aws-amplify/core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { restoreAccountData } from '../../redux/account/account.thunks';
import Routes from '../../routes/Routes';
import AWSConfig from '../../services/aws-config';
import { isIOSFullscreen } from '../../services/utilities';
import FullscreenSpinner from '../FullscreenSpinner/FullscreenSpinner';
import ModalWindow from '../ModalWindow/ModalWindow';
import styles from './App.module.scss';

moment.tz.setDefault('America/New_York');

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure({
  Auth: {
    identityPoolId: AWSConfig.IdentityPoolId,
    region: AWSConfig.region,
    userPoolId: AWSConfig.UserPoolId,
    userPoolWebClientId: AWSConfig.ClientId
  },
  Storage: {
    AWSS3: {
      bucket: AWSConfig.s3bucket,
      region: AWSConfig.s3region
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.isAuthenticating) {
      this.props.restoreAccountData();
    }

    if (isIOSFullscreen()) {
      document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
    }
  }

  componentWillUnmount() {
    if (isIOSFullscreen()) {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }
  }

  handleVisibilityChange = () => {
    const TIMEOUT_NEED_RELOAD = 5000;
    const LAST_HIDDEN = 'last_hidden';
    const updateLastHidden = () => localStorage.setItem(LAST_HIDDEN, `${new Date().getTime()}`);

    if (document.hidden) {
      updateLastHidden();
    } else {
      const now = new Date().getTime();
      const lastFired = +localStorage.getItem(LAST_HIDDEN) || now;
      if (now - lastFired > TIMEOUT_NEED_RELOAD) {
        window.location.reload(true);
      }
    }
  };

  render() {
    const userHasAuthenticated = !this.props.isAuthenticating;
    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
      authenticatedUser: this.props.authenticatedUser,
      role: this.props.role
    };

    return (
      <div className={styles.container}>
        {userHasAuthenticated && <Routes childProps={childProps} />}
        {(this.props.isLoading || !userHasAuthenticated) && <FullscreenSpinner />}
        <ModalWindow />
      </div>
    );
  }
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authenticatedUser: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  restoreAccountData: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
};

App.defaultProps = {
  authenticatedUser: null
};

const mapStateToProps = state => ({
  role: state.account.get('role'),
  authenticatedUser: state.account.get('authenticatedUser'),
  isLoading: state.ui.get('globalIsLoading'),
  isAuthenticated: state.account.get('isAuthenticated'),
  isAuthenticating: state.account.get('isAuthenticating')
});

const mapDispatchToProps = {
  restoreAccountData
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
